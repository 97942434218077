import { Space, Tooltip } from '@mantine/core';
import { color } from 'd3';
import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ObjectActionCreators from '../../actions.js';
import {
  onChangeActivityLog,
  openObjectConfigure,
  setStatusModalImportFile,
  setZoom,
  togglePerspectivesPanel,
} from '../../reducers/layoutReducer/action.js';
import {
  isPerspectivesPanelOpenSelector,
  isShowActivityLogSelector,
  zoomSelector,
} from '../../reducers/layoutReducer/layoutSelector.js';
import ProfileAuth0 from '../user-management/ProfileAuth0.js';

import { devices } from '../../constants/devices.js';
import {
  ActivityLogIcon,
  AddPerspIcon,
  GearIcon,
  ImportIcon,
  ScaleIcon,
  ZoomIn,
  ZoomOut,
} from '../../constants/icon.js';
import { openActivityFromVertical } from '../../reducers/objectReducers/action.js';
import { isMobileSelector } from '../../reducers/responsiveReducer/responsiveSelector.js';
import {
  currentPaletteActiveSelector,
  isDarkModeSelector,
} from '../../reducers/themeReducers/themeSelector.js';
import { ToggleTheme } from '../Button/ToggleTheme.js';
import {
  DivFloatingButton,
  WrapIconFloatingButton,
  Zoom,
  ZoomContainer,
} from '../Main/appStyles.js';
import ButtonShowIconsLibrary from './SwipeMenuMobile/ButtonShowIconsLibrary.js';
import {
  isFuncAdminSelector,
  isSysAdminSelector,
} from '../../reducers/profileReducer/profileSelector.js';

const DividerLine = styled.div({
  border: (props) => `1px solid ${props.borderColor}`,
  width: 36,
  marginLeft: 12,
});
class VerticalButtonBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleCreateNewPerspective =
      this.handleCreateNewPerspective.bind(this);
    this.handleClickStepOne = this.handleClickStepOne.bind(this);
    this.handleOpenObjectConfigure = this.handleOpenObjectConfigure.bind(this);
    this.handleZoomIn = this.handleZoomIn.bind(this);
    this.handleZoomOut = this.handleZoomOut.bind(this);
    this.onToggleActivityLog = this.onToggleActivityLog.bind(this);
    this.handleShowModalImport = this.handleShowModalImport.bind(this);
  }

  handleClickStepOne() {
    this.props.selectObject(null);
    this.props.setObjectToCreate(null);
    this.props.openObjectMenu('addObject');
  }

  handleCreateNewPerspective() {
    this.props.createNewPerspective();
  }

  handleShowModalImport() {
    this.props.showModalImport();
  }

  handleOpenObjectConfigure() {
    this.props.openObjectConfigure(50, 50, null);
  }

  handleZoomIn() {
    if (this.props.zoom === 120) return;
    this.props.setZoom(this.props.zoom + 20);
  }

  handleZoomOut() {
    if (this.props.zoom === 60) return;
    this.props.setZoom(this.props.zoom - 20);
  }

  onToggleActivityLog() {
    this.props.openActivityFromVertical(true);
    this.props.toggleActivityLog(!this.props.isShowActivityLog);
  }

  render() {
    const {
      t,
      isPerspectivesPanelOpen,
      isDarkMode,
      isMobile,
      isSysAdmin,
      isFuncAdmin,
    } = this.props;
    if (isMobile) return null;
    return (
      <VerticalButtonBarContainer>
        <ProfileAuth0 />
        <TooltipButton
          label={isPerspectivesPanelOpen ? t('app.collapse') : t('app.expand')}
        >
          <DivFloatingButton
            pal={this.props.pal}
            className="step1"
            onClick={this.props.togglePerspectivesPanel}
            onTouchEnd={this.props.togglePerspectivesPanel}
            hoverBackgroundColor={'var(--ds-base-intense)'}
            backgroundColor={'var(--ds-base)'}
          >
            {isPerspectivesPanelOpen && <>&#8592;</>}
            {!isPerspectivesPanelOpen && <>&#8594;</>}
          </DivFloatingButton>
        </TooltipButton>
        <TooltipButton label={t('app.add_object')}>
          <DivFloatingButton
            pal={this.props.pal}
            className="step1"
            onClick={this.handleClickStepOne}
            onTouchEnd={this.handleClickStepOne}
            hoverBackgroundColor={'var(--ds-base-intense)'}
            backgroundColor={'var(--ds-base)'}
          >
            +
          </DivFloatingButton>
        </TooltipButton>
        {isSysAdmin && (
          <TooltipButton label={t('app.import_mapping_file')}>
            <DivFloatingButton
              pal={this.props.pal}
              onClick={this.handleShowModalImport}
              onTouchEnd={this.handleShowModalImport}
              hoverBackgroundColor={'var(--ds-base-intense)'}
              backgroundColor={'var(--ds-base)'}
            >
              <WrapIconFloatingButton>
                <ImportIcon />
              </WrapIconFloatingButton>
            </DivFloatingButton>
          </TooltipButton>
        )}
        {isFuncAdmin && (
          <TooltipButton label={t('app.create_new_perspective')}>
            <DivFloatingButton
              pal={this.props.pal}
              onClick={this.handleCreateNewPerspective}
              onTouchEnd={this.handleCreateNewPerspective}
              hoverBackgroundColor={'var(--ds-base-intense)'}
              backgroundColor={'var(--ds-base)'}
            >
              <WrapIconFloatingButton>
                <AddPerspIcon />
              </WrapIconFloatingButton>
            </DivFloatingButton>
          </TooltipButton>
        )}
        {isSysAdmin && (
          <TooltipButton label={t('app.configure_object')}>
            <DivFloatingButton
              pal={this.props.pal}
              onClick={this.handleOpenObjectConfigure}
              onTouchEnd={this.handleOpenObjectConfigure}
              hoverBackgroundColor={'var(--ds-base-intense)'}
              backgroundColor={'var(--ds-base)'}
            >
              <WrapIconFloatingButton>
                <GearIcon />
              </WrapIconFloatingButton>
            </DivFloatingButton>
          </TooltipButton>
        )}
        {isSysAdmin && (
          <TooltipButton
            label={t(isDarkMode ? 'app.night_mode' : 'app.dark_mode')}
          >
            <DivFloatingButton
              pal={this.props.pal}
              hoverBackgroundColor={'var(--ds-base-intense)'}
              backgroundColor={'var(--ds-base)'}
            >
              <ToggleTheme />
            </DivFloatingButton>
          </TooltipButton>
        )}

        {isSysAdmin && <ButtonShowIconsLibrary />}
        {/* <ModalLibraryIcon /> */}

        <DividerLine borderColor={'var(--ds-border-base)'} />
        <TooltipButton label={t('app.full_screen')}>
          <DivFloatingButton
            pal={this.props.pal}
            onClick={window.toggleFullScreen}
            onTouchEnd={window.toggleFullScreen}
            hoverBackgroundColor={'var(--ds-base-intense)'}
            backgroundColor={'var(--ds-base)'}
          >
            <WrapIconFloatingButton>
              <ScaleIcon />
            </WrapIconFloatingButton>
          </DivFloatingButton>
        </TooltipButton>
        <TooltipButton label={t('app.zoom_in')}>
          <DivFloatingButton
            pal={this.props.pal}
            onClick={this.handleZoomIn}
            onTouchEnd={this.handleZoomIn}
            hoverBackgroundColor={'var(--ds-base-intense)'}
            backgroundColor={'var(--ds-base)'}
            disable={this.props.zoom === 120}
          >
            <WrapIconFloatingButton>
              <ZoomIn />
            </WrapIconFloatingButton>
          </DivFloatingButton>
        </TooltipButton>
        <TooltipButton label={t('app.zoom_out')}>
          <DivFloatingButton
            pal={this.props.pal}
            onClick={this.handleZoomOut}
            onTouchEnd={this.handleZoomOut}
            hoverBackgroundColor={'var(--ds-base-intense)'}
            backgroundColor={'var(--ds-base)'}
            disable={this.props.zoom === 60}
          >
            <WrapIconFloatingButton>
              <ZoomOut />
            </WrapIconFloatingButton>
          </DivFloatingButton>
        </TooltipButton>
        <ZoomContainer>
          <Zoom pal={this.props.pal}>{this.props.zoom}%</Zoom>
        </ZoomContainer>
        <Space h={15} />
        <DividerLine borderColor={'var(--ds-border-base)'} />

        {isFuncAdmin && (
          <TooltipButton label={t('app.activity_log')}>
            <DivFloatingButton
              pal={this.props.pal}
              onClick={this.onToggleActivityLog}
              onTouchEnd={this.onToggleActivityLog}
              hoverBackgroundColor={'var(--ds-base-intense)'}
              backgroundColor={'var(--ds-base)'}
            >
              <WrapIconFloatingButton>
                <ActivityLogIcon />
              </WrapIconFloatingButton>
            </DivFloatingButton>
          </TooltipButton>
        )}
      </VerticalButtonBarContainer>
    );
  }
}

export const TooltipButton = ({ children, label = 'tooltip' }) => {
  return (
    <Tooltip
      wrapLines
      withArrow
      transition="fade"
      transitionDuration={200}
      label={label}
      position="right"
      gutter={-10}
      style={{ display: 'inherit' }}
    >
      {children}
    </Tooltip>
  );
};
export const VerticalButtonBarContainer = styled.div({
  height: '100%',
  width: '60px',
  position: 'fixed',
  display: 'block',
  [devices.tablet]: {
    display: 'none',
  },
});
//------------------------------------------------------------------------------
function mapStateToProps(state) {
  return {
    isPerspectivesPanelOpen: isPerspectivesPanelOpenSelector(state),
    pal: currentPaletteActiveSelector(state),
    zoom: zoomSelector(state),
    isShowActivityLog: isShowActivityLogSelector(state),
    isDarkMode: isDarkModeSelector(state),
    isMobile: isMobileSelector(state),
    isSysAdmin: isSysAdminSelector(state),
    isFuncAdmin: isFuncAdminSelector(state),
  };
}

//------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => ({
  createNewPerspective: () =>
    dispatch(ObjectActionCreators.createNewPerspective()),
  togglePerspectivesPanel: () => dispatch(togglePerspectivesPanel()),
  selectObject: (objId) => dispatch(ObjectActionCreators.selectObject(objId)),
  setObjectToCreate: (objId) =>
    dispatch(ObjectActionCreators.setObjectToCreate(objId)),
  openObjectMenu: (action) =>
    dispatch(ObjectActionCreators.openObjectMenu(action)),
  openObjectConfigure: (posX, posY, objId) =>
    dispatch(openObjectConfigure(posX, posY, objId)),
  setZoom: (value) => dispatch(setZoom(value)),
  toggleActivityLog: (value) => dispatch(onChangeActivityLog(value)),
  openActivityFromVertical: (status) =>
    dispatch(openActivityFromVertical(status)),
  showModalImport: () => dispatch(setStatusModalImportFile(true)),
});
//------------------------------------------------------------------------------
export default memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(VerticalButtonBar))
);
