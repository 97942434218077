/* eslint-disable prettier/prettier */
import { colors } from './colors';

export const paletteButtonList = [
  'palette1',
  'palette2',
  'palette3',
  'palette4',
];

const colBgFilterItem = [
  '#D1D0DC',
  '#AFAEC2',
  '#9B9BB3',
  '#4A4F58',
  '#4A4F58',
  '#4A4F58',
];

export const paletteList = {
  isDark: [true, false, true, false, true, false, true, false],
  previewPalette: {
    backgroundColor: [
      colors.io_clay_100,
      colors.io_grey_500,
      colors.io_clay_100,
      colors.io_grey_500,
      colors.io_clay_100,
      colors.io_grey_500,
      colors.io_clay_100,
      colors.io_grey_500,
    ],
  },

  dsBase:         ['#4a74b4','#4a74b4','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  dsBase60: ['#4a74b480','4a74b460','','','','','','','',],
  dsBase80: ['#4a74b480','4a74b460','','','','','','','',],
  dsBaseIntense: ['blue','blue','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  
  dsBgBase:       ['#1f1d1e','#ffffff','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  dsBgBase60: ['#1f1d1e60','#ffffff60','','','','','','','',],
  dsBgBase80: ['#1f1d1e80','#ffffff80','','','','','','','',],
  dsBgLower:      ['#0b0b0b','#eef1f3','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  dsBgOverlay:    ['#353232','#ffffff','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  dsBgSecondary:  ['#353232','#eef1f3','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  
  dsBorderBase:   ['#353232','#dde0e4','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  dsBorderIntense:['#72706f','#b6c1ca','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  
  dsIconActive:     ['#f5f6f6','#14181e','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  dsIconBase:     ['#f5f6f6','#14181e','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  dsIconSecondary:     ['#afadae','#525e6f','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  
  dsTextActive:['#33ddff','#4a74b4','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  dsTextBase:     ['#f5f6f6','#14181e','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  dsTextInvert:['#000000','#ffffff','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  dsTextMuted: ['#9a9797','#6e7c8e','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  dsTextSecondary:['#afadae','#525e6f','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  dsTextTitle:     ['#FFFFFF','#4a74b4','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
  dsTextWhite:     ['#ffffff','#ffffff','#00FFFF','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],

  dscIconActive: '#f5f6f6',
  dscIconBase:   '#f5f6f6',
  dscTextActive: '#33ddff',
  dscTextBase:   '#f5f6f6',
  dscTextSecondary:'#afadae',

  dscBgBase:'#1f1d1e',
  dscBgSecondary: '#353232',

  objFilter: {
    btn: [
      colors.io_grey_600,
      colors.io_white,
      colors.io_clay_600,
      colors.io_white,
      colors.io_violet_blur_600,
      colors.io_white,
      colors.io_green_900,
      colors.io_white,
    ],
    background: [
      colors.br_darkgrey,
      colors.io_white,
      colors.br_darkgrey,
      colors.io_white,
      colors.br_darkgrey,
      colors.io_white,
      colors.br_darkgrey,
      colors.io_white,
    ],
    backgroundContent: [
      colors.io_grey_600,
      colors.br_secblue4,
      colors.io_grey_600,
      colors.br_secblue4,
      colors.io_grey_600,
      colors.br_secblue4,
      colors.io_grey_600,
      colors.br_secblue4,
    ],
  },
  // isDark: [true],
  paletteBtnColor: {
    from: [
      '#959595',
      '#555555',
      '#3D4A5E',
      '#647185',
      '#393268',
      '#837BB5',
      '#305541',
      '#67947C',
    ],
    to: [
      '#555555',
      '#959595',
      '#647185',
      '#3D4A5E',
      '#837BB5',
      '#393268',
      '#67947C',
      '#305541',
    ],
    deg: [60, 80, null, null, 60, 60, 60, 60],
  },

  logo: [
    '/img/logos/logoPositive.svg',
    '/img/logos/logoPositive.svg',
    '/img/logos/logoPositive.svg',
    '/img/logos/logoPositive.svg',
    '/img/logos/logoPositive.svg',
    '/img/logos/logoPositive.svg',
    '/img/logos/logoPositive.svg',
    '/img/logos/logoPositive.svg',
  ],
  body: {
    backgroundColor: [
      colors.io_clay_100,
      colors.io_black,
      colors.io_clay,
      colors.io_haze,
      colors.io_violet,
      colors.io_violet_blur_100,
      colors.io_green_100,
      colors.io_green_1200,
    ],
    borderContent: [
      colors.br_darkgrey,
      'yellow',
      colors.io_clay_300,
      colors.io_white,
      colors.io_violet_100,
      colors.io_violet_blur_100,
      colors.io_green_300,
      colors.io_green_1200,
    ],
    contentNodeBackground: [
      colors.io_martinique_300,
      'pink',
      colors.io_martinique_300,
      colors.io_white2,
      colors.io_violet_300,
      colors.io_violet_blur_200,
      colors.io_green_400,
      colors.io_green_1400,
    ],

    smallTag: [
      colors.br_blue3,
      colors.io_white4,
      colors.io_steel_blaue,
      colors.io_white4,
      colors.io_violet_500,
      colors.io_violet_blur_500,
      colors.io_green_500,
      colors.io_green_1500,
    ],
    profileModalBackground: [
      colors.io_clay_100,
      colors.io_white,
      colors.io_clay_400,
      colors.io_white,
      colors.io_violet_700,
      colors.io_violet_blur_400,
      colors.io_green_600,
      colors.io_green_1600,
    ],
  },
  floatingButton: {
    backgroundColor: [
      colors.io_clay_100,
      'red',
      colors.io_clay_600,
      colors.io_haze_100,
      colors.io_clay_100,
      colors.io_violet_blur_600,
      colors.io_green_700,
      colors.io_green_700,
    ],
    hoverColor: [
      colors.br_softblack,
      colors.br_secblue2,
      colors.io_clay_300,
      colors.io_haze,
      colors.br_softblack,
      colors.io_violet_blur_500,
      colors.io_green_500,
      colors.io_green_1000,
    ],
  },
  pnlPersp: {
    backgroundColor: [
      colors.br_softblack,
      'red',
      colors.io_clay_200,
      colors.br_blue2,
      colors.io_violet_200,
      colors.io_violet_900,
      colors.io_green_200,
      colors.io_green_1300,
    ],
    background: [
      null,
      `linear-gradient(90deg,rgba(51,51,51,1) 12%,rgba(41,41,41,1) 100%)`,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  },
  border: {
    regular: [
      colors.io_grey_200,
      '#e9ecef',
      colors.io_clay_700,
      '#e9ecef',
      colors.io_white2,
      colors.io_grey_300,
      colors.io_white,
      colors.io_grey_400,
    ],
    boxShadow: [
      '-1px 1px 1px 1px #dddddd22',
      '-1px 1px 1px 1px #dddddd22',
      null,
      '-1px 1px 1px 1px #dddddd22',
      null,
      '-1px 1px 1px 1px #ffffff',
      null,
      '-1px 1px 1px 1px #ffffff',
    ],
    hiddenBoxShadow: [true, true, true, true, true, null, true, true],
    borderHightLight: [
      colors.io_sky_blue,
      colors.io_sky_blue,
      colors.io_sky_blue,
      colors.io_sky_blue,
      colors.io_sky_blue,
      colors.io_sky_blue,
      colors.io_sky_blue,
      colors.io_sky_blue,
    ],
  },
  text: {
    primary: [
      colors.br_secblue,
      colors.io_sky_blue100,
      colors.br_secblue,
      colors.io_sky_blue100,
      colors.io_white4,
      colors.io_violet_blur_300,
      colors.io_green_1000,
      colors.io_green_600,
    ],
    primary1: [
      colors.io_white_1,
      colors.br_softblack,
      colors.io_white_1,
      colors.br_softblack,
      colors.io_white_1,
      colors.br_softblack,
      colors.io_white_1,
      colors.br_softblack,
    ],
    secondary: [
      colors.io_grey_200,
      colors.br_softblack,
      colors.io_grey_100,
      colors.br_softblack,
      colors.io_white2,
      colors.br_softblack,
      colors.io_grey_200,
      colors.br_darkgrey,
    ],
    secondary1: [
      colors.io_grey_500,
      colors.io_grey_600,
      colors.io_grey_500,
      colors.io_grey_600,
      colors.io_grey_500,
      colors.io_grey_600,
      colors.io_grey_500,
      colors.io_grey_600,
    ],
    disable: [
      colors.io_grey_200,
      colors.io_grey_200,
      colors.io_grey_200,
      colors.io_grey_200,
      colors.io_grey_200,
      colors.io_grey_200,
      colors.io_grey_200,
      colors.io_grey_200,
    ],
    highlight: [
      colors.io_white_1,
      colors.io_sky_blue,
      colors.io_white_1,
      colors.io_sky_blue,
      colors.io_white_1,
      colors.io_violet_blur_700,
      colors.io_white,
      colors.io_green_900,
    ],
    hightLight1: [
      colors.io_white_1,
      colors.io_sky_blue,
      colors.io_white_1,
      colors.io_sky_blue,
      colors.io_white_1,
      colors.io_violet_blur_700,
      colors.io_white,
      colors.io_green_900,
    ],
    subTitle: [
      colors.io_white4,
      colors.io_martinique,
      colors.io_white4,
      colors.io_sky_blue100,
      colors.io_white4,
      colors.io_violet_blur_600,
      colors.io_white4,
      colors.io_green_1000,
    ],

    description: [
      colors.io_grey_200,
      colors.io_martinique_20,
      colors.io_grey_100,
      colors.io_martinique_200,
      colors.io_grey_100,
      colors.io_martinique_200,
      colors.io_grey_100,
      colors.io_clay_700,
    ],
    active: [
      colors.io_sky_blue,
      colors.io_cornflower_blue,
      colors.io_sky_blue,
      colors.io_cornflower_blue,
      colors.io_sky_blue,
      colors.io_cornflower_blue,
      colors.io_sky_blue,
      colors.io_cornflower_blue,
    ],
    highlight_blur: [
      colors.io_grey_400,
      colors.io_grey_400,
      colors.io_grey_400,
      colors.io_grey_400,
      colors.io_grey_400,
      colors.io_grey_400,
      colors.io_grey_400,
      colors.io_grey_400,
    ],
    perspectives: [null, null, null, colors.io_white_1, null, null, null, null],
    perspectivesHover: [
      null,
      null,
      null,
      colors.br_secblue2,
      null,
      null,
      null,
      null,
    ],
  },
  button: {
    primaryButton: {
      backgroundColor: [
        'rgba(220, 220, 220,0.2)',
        colors.br_secblue4,
        colors.io_clay_500,
        colors.io_white_1,
        colors.io_violet_600,
        colors.io_white_1,
        colors.io_green_800,
        colors.io_green_1400,
      ],
      backgroundColorActive: [
        colors.io_martinique,
        colors.io_grey_300,
        colors.io_clay_400,
        colors.io_grey_300,
        colors.io_violet_800,
        colors.io_grey_300,
        colors.io_green_400,
        colors.io_green_1000,
      ],
      backgroundColorHover: [
        colors.br_darkgrey,
        colors.io_grey_300,
        colors.io_clay_300,
        colors.io_grey_300,
        colors.io_violet_400,
        colors.io_grey_300,
        colors.io_green_700,
        colors.io_green_1200,
      ],
    },
    secondaryButton: {
      backgroundColor: [
        colors.io_martinique_200,
        colors.io_grey_100,
        colors.io_clay_500,
        colors.io_white_1,
        colors.io_violet_600,
        colors.io_white_1,
        colors.io_green_800,
        colors.io_green_1400,
      ],
      backgroundColorActive: [
        colors.io_martinique,
        colors.io_white,
        colors.io_clay_400,
        colors.io_grey_300,
        colors.io_violet_200,
        colors.io_grey_300,
        colors.io_green_400,
        colors.io_green_1000,
      ],
      backgroundColorHover: [
        colors.io_martinique_300,
        colors.io_grey_300,
        colors.io_clay_300,
        colors.io_grey_300,
        colors.io_violet_400,
        colors.io_grey_300,
        colors.io_green_700,
        colors.io_green_1200,
      ],
    },
  },
  select: {
    backgroundColor: [
      colors.io_martinique_200,
      colors.io_white4,
      colors.io_clay_800,
      colors.io_white4,
      colors.io_violet_800,
      colors.io_white4,
      colors.io_green_1100,
      colors.io_green_1000,
    ],
    backgroundColorSelectObj: [
      colors.io_martinique_300,
      colors.io_white_1,
      colors.io_clay_900,
      colors.io_white_1,
      colors.io_violet_800,
      colors.io_white_1,
      colors.io_green_1100,
      colors.io_green_1200,
    ],
  },
  objForm: {
    backgroundColor: [
      colors.br_softblack,
      null,
      colors.io_clay_200,
      null,
      colors.io_violet_200,
      null,
      colors.io_green_200,
      null,
    ],
  },
  activityLog: {
    backgroundColor: [
      colors.br_softblack,
      colors.io_white2,
      colors.io_clay_200,
      colors.io_white2,
      colors.io_violet_200,
      colors.io_white2,
      colors.io_green_200,
      colors.io_white2,
    ],
    backgroundColorBlur: [
      colors.br_softblack,
      colors.io_white,
      colors.io_clay_300,
      colors.br_secblue4,
      colors.io_violet_300,
      colors.br_secblue4,
      colors.io_green_400,
      colors.br_secblue4,
    ],
    colBgBreadCrumb: [
      '#434343',
      colors.io_white2,
      colors.io_clay_300,
      colors.br_secblue4,
      colors.io_violet_300,
      colors.br_secblue4,
      colors.io_green_400,
      colors.br_secblue4,
    ],
  },

  colIconButton: ['white', 'white', 'white', 'white', 'white', 'white'],
  colBgMain: [
    '#596068',
    '#596068',
    '#596068',
    '#596068',
    '#596068',
    '#596068',
    '#596068',
    '#596068',
  ],
  colBgDisc: [
    '#BDBFC3',
    '#BDBFC3',
    '#BDBFC3',
    '#BDBFC3',
    '#BDBFC3',
    '#BDBFC3',
    '#BDBFC3',
    '#BDBFC3',
  ],
  colBgObjAll: [
    '#4A4F58',
    '#4A4F58',
    '#4A4F58',
    '#4A4F58',
    '#4A4F58',
    '#4A4F58',
    '#4A4F58',
    '#4A4F58',
  ],
  colBgFilterItem: [
    colBgFilterItem,
    colBgFilterItem,
    colBgFilterItem,
    colBgFilterItem,
    colBgFilterItem,
    colBgFilterItem,
    colBgFilterItem,
    colBgFilterItem,
  ],
  colBgObjMain: [
    '#3B404A',
    colors.io_white,
    '#3B404A',
    colors.io_white,
    colors.io_violet_400,
    colors.io_white,
    colors.io_green_900,
    colors.io_green_1600,
  ],
  colBgObjTransp: [
    '#3B404A55',
    '#3B404A55',
    '#3B404A55',
    '#3B404A55',
    '#3B404A55',
    '#3B404A55',
    '#3B404A55',
    '#3B404A55',
  ],
  colBgHover: [
    '#27496d33',
    '#27496d33',
    '#27496d33',
    '#27496d33',
    '#27496d33',
    '#27496d33',
    '#27496d33',
    '#27496d33',
  ],
  colBgForm: [
    '#4A4F58',
    '#4A4F58',
    '#4A4F58',
    '#4A4F58',
    '#4A4F58',
    '#4A4F58',
    '#4A4F58',
    '#4A4F58',
  ],
  colBgFld: [
    '#59606855',
    '#59606855',
    '#59606855',
    '#59606855',
    '#59606855',
    '#59606855',
    '#59606855',
    '#59606855',
  ],
  colLbl: [
    '#AAAAAA',
    '#444444',
    '#7FC97F',
    '#7FC97F',
    '#7FC97F',
    '#7FC97F',
    '#7FC97F',
    '#7FC97F',
  ],
  colLblDisc: ['red', 'red', 'red', 'red', 'red', 'red', 'red', 'red'],
  colFldVal: ['white', 'white', 'white', 'white', 'white', 'white'],
  colFldValOnLight: ['white', 'white', 'white', 'white', 'white', 'white'],
  colFldValDisc: ['red', 'red', 'red', 'red', 'red', 'red'],
  colTxt: ['#CCCCCC', '#777777', 'white', 'white', 'white', 'white'],
  colTxtHeader: ['white', 'white', 'white', 'white', 'white', 'white'],
  colTxtHeaderOnLight: ['white', 'white', 'white', 'white', 'white', 'white'],
  colTxtOnLight: ['white', 'white', 'white', 'white', 'white', 'white'],
  colTxtTitle: ['white', 'white', 'white', 'white', 'white', 'white'],
  colTxtFlash: ['red', 'red', 'red', 'red', 'red', 'red'],
  colBgFlash: ['red', 'red', 'red', 'red', 'red', 'red'],
  colArrow: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
  colLineSeparator: [
    '#FFFFFF11',
    '#FFFFFF11',
    '#FFFFFF11',
    '#FFFFFF11',
    '#FFFFFF11',
    '#FFFFFF11',
  ],
  colBorderDark: [
    '#22262C77',
    '#22262C77',
    '#22262C77',
    '#22262C77',
    '#22262C77',
    '#22262C77',
  ],
  colBorder: [
    '#3E434B77',
    '#3E434B77',
    '#3E434B77',
    '#3E434B77',
    '#3E434B77',
    '#3E434B77',
  ],
  colBorderLight: [
    '#FFFFFF11',
    '#FFFFFF11',
    '#FFFFFF11',
    '#FFFFFF11',
    '#FFFFFF11',
    '#FFFFFF11',
  ],
};
