import {
  ActionIcon,
  Button,
  Group,
  Modal,
  SegmentedControl,
  Space,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { cloneDeep, isArray, isEmpty } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { TrashX } from 'tabler-icons-react';
import { v4 as uuidV4 } from 'uuid';

import produce from 'immer';
import { useTranslation } from 'react-i18next';
import ObjectApi, { ipServer } from '../../api/ObjectApi';
import { FolderIcon } from '../../constants/icon';
import {
  setCurrentIconSelect,
  updateDataIcons,
} from '../../reducers/iconsLibraryReducer/action';
import {
  actionAddResourceLibrarySelector,
  iconsLibraryAvailableSelector,
  iconsLibrarySelector,
  isLoadingDataLibrarySelector,
  modeIconLibrarySelector,
  sessionModalLibrarySelector,
} from '../../reducers/iconsLibraryReducer/iconsLibrarySelector';
import DotLoader from '../DotLoader/DotLoader';
import ModalAddResource from './ModalAddResource';
import {
  CategoriesLibraryCategory,
  CategoriesLibraryIconsContainer,
  WrapButtonCategory,
} from './ModalLIbraryIconCss';
import ActionCategory from './ActionCategory';
import { MODE_LIBRARY_ICON } from '../../reducers/iconsLibraryReducer/type';
import { segmentedControlStyles } from '../UI/SegmentedControlStylesCSS';
import { modalStyle } from '../../constants/commonStyles';

export default function CategoriesLibraryIcons({
  currentSession,
  currentCategory,
  setCurrentSession,
  onChangeCategory,
}) {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const iconsLibrary = useSelector(iconsLibrarySelector);
  const iconsLibraryAvailable = useSelector(iconsLibraryAvailableSelector);
  const dataSessionLibraryIcons = useSelector(sessionModalLibrarySelector);
  const actionAddResource = useSelector(actionAddResourceLibrarySelector);
  const isLoadingDataLibrary = useSelector(isLoadingDataLibrarySelector);
  const firstRender = useRef(false);

  const [isShowModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
  const [categorySelectDelete, setCategorySelectDelete] = useState(false);

  const { t } = useTranslation();
  const onHideModalConfirmDelete = useCallback(() => {
    setShowModalConfirmDelete(false);
  }, []);
  // const onShowModalConfirmDelete = useCallback(() => {
  //   setShowModalConfirmDelete(true);
  // }, []);

  const categoriesBySession = useMemo(() => {
    if (isEmpty(iconsLibraryAvailable)) return null;
    const categories = Object.entries(
      iconsLibraryAvailable[currentSession].categories
    ).map(([key, value]) => {
      return { ...value, parentId: key };
    });

    return categories;
  }, [currentSession, iconsLibraryAvailable]);

  useEffect(() => {
    if (firstRender.current === false && categoriesBySession?.length > 0) {
      //  onChangeCategory(categoriesBySession[0]); COMMENTED AVS 18/07/24
      firstRender.current = true;
    }
  }, [categoriesBySession]);

  const onAddCategory = async (data = [], callback) => {
    try {
      const newData = cloneDeep(iconsLibrary);
      if (isArray(data?.urlsImage)) {
        data?.urlsImage?.map((url) => {
          newData[currentSession].categories[uuidV4()] = {
            name: url?.name,
            icon: url?.filepath,
            id: uuidV4(),
            icons: {},
          };
        });
      }

      await ObjectApi.saveLibrary(JSON.stringify(newData));
      setTimeout(() => {
        dispatch(updateDataIcons(newData));
        if (typeof callback === 'function') callback();
      }, 250);
    } catch (error) {
      console.log(
        '🚀 ~ file: CategoriesLibraryIcons.js:38 ~ onAddCategory ~ error:',
        error
      );
    }
  };

  const handleChangeSession = (session) => {
    const categories = Object.entries(
      iconsLibraryAvailable[session].categories
    ).map(([key, value]) => {
      return { ...value, parentId: key };
    });

    if (categories?.length > 0) {
      onChangeCategory(categories[0]);
    } else {
      onChangeCategory(null);
    }
    setCurrentSession(session);
  };

  const handleDeleteCategory = async () => {
    if (isEmpty(categorySelectDelete)) return;
    const newData = produce(iconsLibrary, (draffState) => {
      delete draffState[currentSession].categories[
        categorySelectDelete?.parentId
      ];
    });
    dispatch(updateDataIcons(newData));
    dispatch(setCurrentIconSelect(null));
    onChangeCategory(null);
    setCategorySelectDelete(null);
    setShowModalConfirmDelete(false);
    await ObjectApi.saveLibrary(JSON.stringify(newData));
  };
  const handleUpdateCategory = async ({
    categoryUpdate,
    name,
    icon,
    callBack,
  }) => {
    if (isEmpty(categoryUpdate)) return;
    const newData = produce(iconsLibrary, (draffState) => {
      draffState[currentSession].categories[categoryUpdate?.parentId].name =
        name;
      draffState[currentSession].categories[categoryUpdate?.parentId].icon =
        icon;
    });
    console.log('newData', newData);
    await ObjectApi.saveLibrary(JSON.stringify(newData));
    dispatch(updateDataIcons(newData));
    console.log('callBack', callBack);
    if (typeof callBack === 'function') callBack();
    // dispatch(setCurrentIconSelect(null));
    // onChangeCategory(null);
    // setCategorySelectDelete(null);
    // setShowModalConfirmDelete(false);
  };

  return (
    <>
      <CategoriesLibraryIconsContainer>
        {isLoadingDataLibrary ? (
          <DotLoader noSpacing={true} />
        ) : (
          <>
            <SegmentedControl
              disabled={isLoadingDataLibrary}
              value={currentSession}
              onChange={handleChangeSession}
              styles={{
                root: {
                  width: '100%',
                },
                ...segmentedControlStyles(),
              }}
              data={dataSessionLibraryIcons}
            />

            <CategoriesLibraryCategory>
              <Space h="8px" />
              {actionAddResource && currentSession !== 'svg' && (
                <>
                  <ModalAddResource
                    type={'add_category'}
                    onSave={onAddCategory}
                    multiple={false}
                  />
                </>
              )}

              {categoriesBySession?.map((category) => {
                return (
                  <ButtonCategory
                    key={category?.id}
                    category={category}
                    currentCategory={currentCategory}
                    onClick={onChangeCategory}
                    setCategorySelectDelete={setCategorySelectDelete}
                    setShowModalConfirmDelete={setShowModalConfirmDelete}
                    handleUpdateCategory={handleUpdateCategory}
                  />
                );
              })}
            </CategoriesLibraryCategory>
          </>
        )}
      </CategoriesLibraryIconsContainer>
      <Modal
        zIndex={20000000009}
        opened={isShowModalConfirmDelete}
        onClose={onHideModalConfirmDelete}
        title={t('delete_category')}
        styles={modalStyle}
      >
        <Text weight={400}>{t('delete_category_icon_confirm')}</Text>
        <Space h={12} />
        <Group spacing="sm">
          <Button
            onClick={handleDeleteCategory}
            compact
            color="red"
            rightIcon={<TrashX size={16} />}
          >
            {t('ok')}
          </Button>
          <Button onClick={onHideModalConfirmDelete} compact>
            {t('cancel')}
          </Button>
        </Group>
      </Modal>
    </>
  );
}

export const ButtonCategory = ({
  category,
  currentCategory,
  onClick,
  children,
  type,
  setCategorySelectDelete,
  setShowModalConfirmDelete,
  handleUpdateCategory,
}) => {
  const handleClick = () => {
    onClick(category);
  };

  const onClickDeleteCategory = () => {
    setCategorySelectDelete(category);
    setShowModalConfirmDelete(true);
  };

  const modeIconLibrary = useSelector(modeIconLibrarySelector);
  const isSelected = category && category?.id === currentCategory?.id;

  return (
    <div>
      <WrapButtonCategory>
        <ActionIcon
          onClick={handleClick}
          styles={{
            root: {
              width: '100%',
              ':hover': {
                backgroundColor: 'transparent',
              },
              justifyContent: 'flex-start',
            },
          }}
        >
          {children || (
            <>
              {category?.icon ? (
                <ImageIcon
                  radius="md"
                  src={`${ipServer + category?.icon}`}
                  alt=""
                />
              ) : (
                <FolderIcon size={20} />
              )}
              <Space w={14} />
              <Text
                size="sm"
                weight={isSelected ? 800 : ''}
                color={
                  isSelected
                    ? 'var(--ds-text-base)'
                    : 'var(--ds-text-secondary)'
                }
              >
                {category?.name}
              </Text>
            </>
          )}
        </ActionIcon>

        {category?.parentId !== 'all' &&
          type !== 'add_category' &&
          modeIconLibrary === MODE_LIBRARY_ICON.VIEW_AND_ADD_ICON && (
            <ActionCategory
              onClickDeleteCategory={onClickDeleteCategory}
              category={category}
              handleUpdateCategory={handleUpdateCategory}
            />
          )}
      </WrapButtonCategory>
    </div>
  );
};

const ImageIcon = styled.img({ width: 30, height: 30, borderRadius: 4 });
