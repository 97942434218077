import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const CssVariableUpdater = () => {
  const dataColumns = useSelector((state) => state.objects.dataColumns);
  const filters = useSelector((state) => state.objects.filters);

  const getColumnsForLevel = (level) => {
    const objectType = filters[`level${level}`];
    return dataColumns[level].filter((a) =>
      objectType?.toLowerCase().startsWith(a.to)
    );
  };

  const getColumsWitdh = (level) => {
    const columns = getColumnsForLevel(level);
    // Exclure la dernière colonne du `map`
    const widths = columns
      .slice(0, -1)
      .map((item) => ` ${item.w}px `)
      .join(' ');
    // 20 px au debut pour l'icone expand
    // Ajouter '1fr' pour la dernière colonne qui occupe l'espace restant
    return `auto ${widths} 1fr`;
  };

  useEffect(() => {
    if (dataColumns) {
      // Update the global CSS variable
      for (let i = 0; i < 3; i++) {
        document.documentElement.style.setProperty(
          `--grid-template-${i}`,
          ` auto / ${getColumsWitdh(i)}`
        );
      }
    }
  }, [dataColumns, filters]);

  return null; // This component doesn't render anything
};

export default CssVariableUpdater;
