import { ActionIcon, Image, Modal, Transition } from '@mantine/core';
import React, { startTransition } from 'react';
import ObjectActionCreators from '../actions.js';
import DotLoader from '../components/DotLoader/DotLoader.js';
import {
  AppContentContainer,
  DivMain,
  ViewProfileOutsideClick,
} from '../components/Main/appStyles.js';
import Notification from '../components/Notification/Notification.js';

import ModalLIbraryIcon from '../components/ModalLIbraryIcon/index.js';
import PreviewSvgSketch from '../components/PreviewSvgSketch/index.js';
import {
  MenuMobile,
  SwipeMenuMobile,
} from '../components/layout/SwipeMenuMobile';
import TabletLayout from '../components/layout/TabletLayout.js';
import { closeObjectDetails } from '../reducers/layoutReducer/action.js';
import { fetchObjects } from '../reducers/objectReducers/action.js';
import { OBJECTS_TYPE } from '../reducers/objectReducers/type.js';
import store from '../store/configureStore.js';
import { isTablet } from '../utils/detectDevices.js';
import { NotificationContainer, ViewBackPerspective } from './appCSS.js';
import { initialStateTheme } from '../reducers/themeReducers/themeReducer.js';
import { parseJson } from '../utils/parseJson.js';
import ModalMappingFile from '../components/ModalMappingFile/index.js';
import { modalStyle, updateCSSVariables } from '../constants/commonStyles.js';
import CssVariableUpdater from '../components/layout/DataColumns/CSSVariableUpdater.js';

const PalletSelect = React.lazy(() =>
  import('../components/PalletSelect/PalletSelect.js')
);

const Setting = React.lazy(() => import('../components/Setting'));

const ActivityLog = React.lazy(() => import('../components/ActivityLog'));
const WrapObjectConfigure = React.lazy(() =>
  import('../components/UI/Objects/ObjectConfigure/WrapObjectConfigure')
);

const Perspectives = React.lazy(() =>
  import('../components/UI/Perspectives/Perspectives.js')
);
const WrapObjForm = React.lazy(() =>
  import('../components/UI/Objects/ObjForm/WrapObjForm')
);

const NavigTree = React.lazy(() => import('../components/layout/NavigTree'));
const ObjectCreator = React.lazy(() => import('../components/object-creator'));
const ObjectSelector = React.lazy(() =>
  import('../components/object-selector')
);
const NavigRemoteControllerContainer = React.lazy(() =>
  import('../components/UI/NavigRemoteController/NavigRemoteController.js')
);
class Navig extends React.Component {
  async changeObjValue(trgName, trgValue) {
    if (this.props.draggedObjectFormData instanceof Promise) {
      await this.props.draggedObjectFormData;
    }

    const newFormData = this.props.draggedObjectFormData.map((obj, ind) => {
      return obj.attrName === trgName
        ? { ...obj, value: trgValue, defaultValue: trgValue }
        : obj;
    });
    await this.props.setDraggedObjFormData(newFormData);
    await this.props.saveObjectFormData(
      this.props.dragged_node_key,
      newFormData
    );
    await this.props.fetchObjects(this.props.dragged_node_key);
  }

  //------------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
    this.state = {
      startedDrag: null,
      objects: '',
      parent: '',
      objFormData: '',
      // objId: '',
      context: 'bzzz',
      rndShowGantt: true,
      qb_restrictions: null,
      pattern: null,
    };

    this.interval = null;
    this.onRemoteControllerShowToggle =
      this.onRemoteControllerShowToggle.bind(this);
    this.changeObjValue = this.changeObjValue.bind(this);
    this.handleClickLevel = this.handleClickLevel.bind(this);
    this._handleContextMenu = this._handleContextMenu.bind(this);
    this.handleClickNone = this.handleClickNone.bind(this);
    this.onHiddenProfileModal = this.onHiddenProfileModal.bind(this);
    window.addEventListener('mousedown', this.handleClickNone);
    window.addEventListener('mousedown', this.registerPos.bind(this));
    window.addEventListener('mouseup', this.registerPos.bind(this));
    this.onSetShowNodeContent = this.onSetShowNodeContent.bind(this);
    this.handleStartDrag = this.handleStartDrag.bind(this);
    this.handleEndDrag = this.handleEndDrag.bind(this);
    this.handleDrop = this.handleDrop.bind(this);

    store.subscribe(() => {
      const lastAction = store.getState().lastAction.type;
      if (
        (lastAction === OBJECTS_TYPE.POST_CREATED_FROM_OBJ_DEF) |
        (lastAction == OBJECTS_TYPE.POST_CREATED_PERSP_FROM_OBJ_DEF)
      ) {
        const objects = store.getState().objects;
        this.props.openObjectDetails();
        this.props.refreshTreeByPattern();
        this.props.selectObject(objects.objId);
      }
    });
  }

  //------------------------------------------------------------------------------------------------
  registerPos(evt) {
    this.props.setMousePos(evt.clientX, evt.clientY);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      JSON.stringify(props.pattern) !== JSON.stringify(state.pattern) ||
      JSON.stringify(props.qb_restrictions) !==
        JSON.stringify(state.qb_restrictions)
    ) {
      return {
        pattern: props.pattern,
        qb_restrictions: props.qb_restrictions,
      };
    }
    return null;
  }

  //------------------------------------------------------------------------------------------------
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.props.pattern) !==
        JSON.stringify(prevProps.pattern) ||
      JSON.stringify(this.props.qb_restrictions) !==
        JSON.stringify(prevProps.qb_restrictions)
    ) {
      startTransition(() => {
        this.props.getTreeByPattern(this.props.pattern);
      });
    }
    if (this.props.pal && prevProps.pal !== this.props.pal) {
      updateCSSVariables(this.props.pal);
    }
  }

  //------------------------------------------------------------------------------------------------
  handleDrop(keyTarget) {
    console.log('handleDrop>>>trigger', keyTarget);
    this.changeObjValue(this.props.classifX, keyTarget);
    this.props.setNodeDraggedOver(null);
  }

  //------------------------------------------------------------------------------------------------
  handleEndDrag() {
    console.log('handleEndDrag>>>>>>here<<<<<<');
    this.setState({ startedDrag: null });
  }

  //------------------------------------------------------------------------------------------------
  handleStartDrag(key) {
    console.log('handleStartDrag>>>>>>here<<<<<<', key);
    this.props.startDraggingNodes(key);
    this.setState({ startedDrag: key });
  }

  //------------------------------------------------------------------------------------------------
  handleClickNone(evt) {
    if (evt.target.id === 'divNavigPanel' || evt.target.id === 'svgPanel') {
      if (window.isShowSelectedNodeView) {
        window.isShowSelectedNodeView = false;
        this.props.onShowSelectedNode(false);
        this.props.onResetSelectNode();
      }
      this.setState({ parent: '' });
      store.dispatch(ObjectActionCreators.selectObject(null));
      store.dispatch(closeObjectDetails());
      if (this.props.remote_ctrl_visible) {
        this.props.remoteControllerShowToggle(0, 0);
      }
    }
  }

  //------------------------------------------------------------------------------------------------
  handleClickLevel(obj) {
    this.setState(
      {
        parent: obj,
        rndShowGantt: 1 + Math.floor(Math.random() * Math.floor(16)) == 1,
      },
      async () => {
        await store.dispatch(ObjectActionCreators.selectObject(obj));
        await store.dispatch(fetchObjects(obj));
      }
    );
  }

  //------------------------------------------------------------------------------------------------
  onRemoteControllerShowToggle() {
    this.props.remoteControllerShowToggle(0, 0);
  }

  onSetShowNodeContent({ clientX }) {
    this.props.setShowNodeContent(false);
  }

  //------------------------------------------------------------------------------------------------
  render() {
    const {
      isDevelopment,
      t,
      pal,
      auth0,
      isPerspectivesPanelOpen,
      remote_ctrl_visible,
      menuOpen,
      isObjectSelectorOpen,
      closeObjectMenu,
      closeSelectObject,
      isShowProfileView,
      isMobile,
      isShowNodeContent,
      objectCreatorState,
    } = this.props;
    const ocs = objectCreatorState;
    const { isLoading, isAuthenticated, loginWithRedirect } = auth0 || {};
    if (isDevelopment ? false : isLoading) {
      return <div>Loading...</div>;
    }
    if (isDevelopment ? false : !isAuthenticated) {
      loginWithRedirect();
    }

    return (
      <>
        {(isDevelopment ? true : isAuthenticated) && (
          <DivMain>
            <CssVariableUpdater />
            <React.Suspense fallback={<DotLoader />}>
              <Transition
                mounted={(isMobile && !isShowNodeContent) || !isMobile}
                transition="fade"
                duration={500}
                timingFunction="ease"
              >
                {(styles) => (
                  <div style={{ ...styles, height: '95%', display: 'initial' }}>
                    <Perspectives />
                  </div>
                )}
              </Transition>
            </React.Suspense>
            {isMobile && isShowNodeContent && (
              <ViewBackPerspective id="ViewBackPerspective">
                <ActionIcon
                  variant="transparent"
                  size="lg"
                  onClick={this.onSetShowNodeContent}
                >
                  <Image
                    radius="md"
                    src="perspective_logo.png"
                    alt="back_perspective_icon"
                  />
                </ActionIcon>
              </ViewBackPerspective>
            )}

            <SwipeMenuMobile />
            <MenuMobile />

            <Transition
              mounted={(isMobile && isShowNodeContent) || !isMobile}
              transition="slide-left"
              duration={500}
              timingFunction="ease"
            >
              {(styles) => (
                <div style={{ ...styles, height: '100%', display: 'initial' }}>
                  <AppContentContainer
                    pal={pal}
                    id="AppContentContainer"
                    margin={'10px'}
                    isPerspectivesPanelOpen={isPerspectivesPanelOpen}
                    isShowNodeContent={isShowNodeContent}
                  >
                    <React.Suspense fallback={null}>
                      <Setting />
                    </React.Suspense>
                    <Modal
                      styles={modalStyle}
                      opened={remote_ctrl_visible}
                      title={t('display.configuration')}
                      position={{ bottom: 20, left: 20 }}
                      overlayOpacity={0.45}
                      onClose={this.onRemoteControllerShowToggle}
                      size="xs"
                    >
                      <React.Suspense fallback={<DotLoader />}>
                        <NavigRemoteControllerContainer
                          id="remoteController"
                          height={'450px'}
                        />
                      </React.Suspense>
                    </Modal>

                    <React.Suspense fallback={<DotLoader />}>
                      <NavigTree
                        handleDrop={this.handleDrop}
                        handleEndDrag={this.handleEndDrag}
                        handleStartDrag={this.handleStartDrag}
                        handleClickLevel={this.handleClickLevel}
                      />
                    </React.Suspense>
                    <React.Suspense fallback={null}>
                      <ActivityLog />
                    </React.Suspense>

                    <React.Suspense fallback={null}>
                      <PreviewSvgSketch />
                    </React.Suspense>

                    <WrapObjForm />
                    <WrapObjectConfigure />
                    <Modal
                      id="modal_add_object"
                      styles={{
                        modal: {
                          ...modalStyle.modal,
                          padding: isMobile ? '0px 12px !important' : 20,
                          minHeight: isMobile && '100%',
                        },
                        title: {
                          ...modalStyle.title,
                          paddingTop: isMobile && 20,
                        },
                        inner: isMobile
                          ? {
                              padding: 0,
                            }
                          : {},
                      }}
                      size="xl"
                      title={t(
                        ocs.action === 'addObject'
                          ? 'app.add_object'
                          : 'app.move_object'
                      )}
                      opened={menuOpen}
                      onClose={closeObjectMenu}
                    >
                      <React.Suspense fallback={<DotLoader />}>
                        <ObjectCreator mode="normal" />
                      </React.Suspense>
                    </Modal>
                    <Modal
                      id="modal_select_object"
                      styles={{
                        modal: {
                          ...modalStyle.modal,
                          padding: isMobile ? '0px 12px !important' : 20,
                          minHeight: isMobile && '100%',
                        },
                        title: {
                          ...modalStyle.title,
                          paddingTop: isMobile && 20,
                        },
                        inner: isMobile
                          ? {
                              padding: 0,
                            }
                          : {},
                      }}
                      size="xl"
                      title={t('app.select_object')}
                      opened={isObjectSelectorOpen}
                      onClose={closeSelectObject}
                    >
                      <React.Suspense fallback={<DotLoader />}>
                        <ObjectSelector mode="normal" />
                      </React.Suspense>
                    </Modal>
                  </AppContentContainer>
                </div>
              )}
            </Transition>
          </DivMain>
        )}
        <ModalMappingFile />

        <React.Suspense>
          <PalletSelect />
        </React.Suspense>
        {isShowProfileView && (
          <>
            <ViewProfileOutsideClick
              onClick={this.onHiddenProfileModal}
              onTouchEnd={this.onHiddenProfileModal}
            />
          </>
        )}
        <NotificationContainer>
          <Notification />
        </NotificationContainer>
        <TabletLayout />
        <ModalLIbraryIcon />
      </>
    );
  }

  //------------------------------------------------------------------------------------------------
  componentWillUnmount() {
    clearInterval(this.interval);
    if (!this.props.isMobile) {
      document.removeEventListener('contextmenu', this._handleContextMenu);
      window.removeEventListener('keydown', this.handleShowSelectedNode);
      window.removeEventListener('keyup', this.handleHideSelectedNode);
    }
  }

  onHiddenProfileModal() {
    this.props.onHiddenProfileView();
    this.props.onResetOptionMenu();
  }

  handleShowSelectedNode = (event) => {
    if (event.keyCode === 17) {
      const { configure_object, objDetailsOpen, menuOpen } = this.props;
      if (configure_object || objDetailsOpen || menuOpen) return;
      if (document.getSelection().toString().length !== 0) return;
      const activeElement = document.activeElement;
      console.log(activeElement.tagName, activeElement.type);
      const listElementDeactivate = ['INPUT', 'TEXTAREA'];
      if (listElementDeactivate.includes(activeElement.tagName)) return;
      if (window.isShowSelectedNodeView) {
        window.isShowSelectedNodeView = false;
        this.props.onShowSelectedNode(false);
        this.props.onResetSelectNode();
      } else {
        window.isShowSelectedNodeView = true;
        this.props.onShowSelectedNode(true);
      }
    }
  };

  handleHideSelectedNode = (event) => {
    if (event.keyCode === 27 && window.isShowSelectedNodeView) {
      window.isShowSelectedNodeView = false;
      this.props.onShowSelectedNode(false);
      this.props.onResetSelectNode();
    }
  };

  //------------------------------------------------------------------------------------------------
  componentDidMount() {
    const currentPalette = parseJson(localStorage.getItem('currentPalette'));

    if (currentPalette) {
      this.props.getLocalPalette(
        currentPalette?.indexPalette,
        currentPalette?.themeMode,
        currentPalette?.palette
      );
    } else {
      this.props.getLocalPalette(
        initialStateTheme?.currentPaletteIndex,
        initialStateTheme?.themeMode,
        initialStateTheme?.currentPalette
      );
    }

    this.props.getProfiles();

    if (!this.props.isMobile) {
      window.addEventListener('keydown', this.handleShowSelectedNode);
      window.addEventListener('keyup', this.handleHideSelectedNode);
      document.addEventListener('contextmenu', this._handleContextMenu);
    }

    if (this.props.pal) {
      updateCSSVariables(this.props.pal);
    }
  }

  //------------------------------------------------------------------------------------------------
  _handleContextMenu(e) {
    if (e) {
      e.preventDefault();
      if (this.props.isMobile || isTablet) return;
      this.props.remoteControllerShowToggle(e.clientX, e.clientY);
    }
  }
  //------------------------------------------------------------------------------------------------
}
export { Navig };
