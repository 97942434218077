import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { devices } from '../../constants/devices';

export const appStyles = {
  perspectivesSlider: {
    display: 'inline-block',
    width: '120px',
    height: '100%',
    border: '0px solid red',
    paddingTop: '6px',
  },
  geo: {
    marginTop: '-2px',
    marginLeft: '30px',
  },
  isDevContainer: {
    verticalAlign: 'top',
    display: 'inline-block',
    position: 'relative',
    top: '0px',
    lineHeight: '30px',
    height: '100%',
  },
  setting: {
    backgroundColor: '#444',
    zIndex: 10000,
    position: 'relative',
  },
  searchField: {
    display: 'inline',
  },
  titleSetting: {
    display: 'inline',
    fontSize: '10px',
  },
  modalTitle: {
    bottom: 20,
    left: 20,
  },
};

export const AppContentContainer = styled.div({
  border: 'var(--ds-border-base)',
  overflow: 'hidden',
  boxShadow: (props) =>
    props?.pal?.border?.hiddenBoxShadow
      ? ''
      : `0px 0px 3px 1px ${props?.pal?.border?.regular || '#000'}`,
  height: '98%',
  backgroundColor: 'var(--ds-bg-base)',
  borderRadius: '10px',
  margin: (props) => props.margin,
  width: (props) =>
    `calc(100% - ${props.isPerspectivesPanelOpen ? '315px' : '31px'})`,
  float: 'right',
  [devices.tablet]: {
    margin: 0,
    width: (props) => (props.isShowNodeContent ? '100%' : '0px'),
    // touchAction: 'pan-y',
  },
  [devices.mobileL]: {
    margin: 0,
    width: (props) => (props.isShowNodeContent ? '100%' : '0px'),
  },
});

export const calculateWithAppContentContainer = ({
  isPerspectivesPanelOpen,
  isMobile,
  isShowNodeContent,
}) => {
  if (isMobile) {
    if (isShowNodeContent) {
      return '100%';
    }
    return '0px';
  }
  return `calc(100% - ${isPerspectivesPanelOpen ? '315px' : '31px'})`;
};

//------------------------------------------------------------------------------------------------
export const DivMain = styled.div({
  color: 'white',
  backgroundSize: '100%',
  zIndex: 50000,
  paddingTop: '5px',
  height: '100vh',
  opacity: 1,
  backgroundColor: '#000000',
});

//------------------------------------------------------------------------------------------------
export const ImgIconSVG = styled.img({
  display: 'inline-block',
  height: '20px',
  verticalAlign: 'top',
  padding: '0px',
  backgroundColor: 'transparent',
  opacity: '0.3',
  marginRight: '10px',
  // filter: 'invert(100%)',
});
//------------------------------------------------------------------------------------------------
export const DivLogoHeader = styled.div({
  borderRadius: '8px',
  backgroundColor: '#FFFFFF',
  // boxShadow:'2px 2px 2px  #555',
  // minheight:'38px',
  marginTop: '10px',
  minHeight: '38px',
  marginLeft: '30px',
  position: 'fixed',
  padding: '9px',
  paddingLeft: '5px',
  paddingRight: '10px',
  verticalAlign: 'top',
  width: '230px',
  bottom: '50px',
});
//------------------------------------------------------------------------------------------------
export const DivFloatingButton = styled.div({
  color: 'white',
  backgroundColor: (props) => (props?.isTransparent ? '' : 'var(--ds-base)'),
  height: '30px',
  margin: '15px',
  width: '30px',
  opacity: (props) => (props.disable ? '0.6' : '1'),
  borderRadius: '50%',
  // border: '0.8px solid rgba(225,225,225,0.6)',
  lineHeight: '27px',
  padding: 'auto',
  paddingRight: '0px',
  paddingLeft: '0px',
  verticalAlign: 'middle',
  textAlign: 'center',
  fontSize: '25px',
  cursor: (props) => (props.disable ? '' : 'pointer'),
  zIndex: '10000000000',
  ':hover': {
    boxShadow: '1px 2px 10px -4px #555555',
    backgroundColor: 'var(--ds-base-intense)',
  },
});
//------------------------------------------------------------------------------------------------
export const InputRange = styled.input({
  display: 'inline-block',
  height: '100%',
});

export const WrapIconFloatingButton = styled.div({
  alignItems: 'center',
  border: '0px solid rgba(225, 225, 225, 0.6)',
  borderRadius: 30,
  display: 'flex',
  height: 30,
  width: 30,
  padding: 4,
  justifyItems: 'center',
});

export const ViewProfileOutsideClick = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  top: 0,
  zIndex: 10,
});

export const ZoomContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
export const Zoom = styled.div({
  border: (props) =>
    `1px solid ${props?.pal?.border?.regular || colors.io_grey_100}`,
  color: (props) => props.pal.text.secondary,
  width: '70%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 3,
  fontSize: '12px',
});
