import { withAuth0 } from '@auth0/auth0-react';
import debounce from 'lodash/debounce';
import { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navig } from './app';

import ObjectActionCreators from '../actions';
import {
  onHiddenProfileView,
  onResetOptionMenu,
  onResetSelectNode,
  onShowSelectedNode,
  openObjectDetails,
  remoteControllerShowToggle,
  setMousePos,
} from '../reducers/layoutReducer/action.js';
import {
  configure_objectSelector,
  isPerspectivesPanelOpenSelector,
  isShowProfileViewSelector,
  objDetailsOpenSelector,
  remote_ctrl_visibleSelector,
} from '../reducers/layoutReducer/layoutSelector.js';
import {
  fetchObjects,
  getProfiles,
  getTreeByPattern,
  refreshTreeByPattern,
  setNodeDraggedOver,
  startDraggingNodes,
} from '../reducers/objectReducers/action';
import { onSetShowNodeContent } from '../reducers/responsiveReducer/action';
import {
  isMobileSelector,
  isShowNodeContentSelector,
} from '../reducers/responsiveReducer/responsiveSelector';
import { getLocalPalette } from '../reducers/themeReducers/action';
import { currentPaletteActiveSelector } from '../reducers/themeReducers/themeSelector';
//------------------------------------------------------------------------------------------------
function mapStateToProps(state) {
  return {
    isPerspectivesPanelOpen: isPerspectivesPanelOpenSelector(state),
    pal: currentPaletteActiveSelector(state),
    classifX: state.objects.classifX,
    remote_ctrl_visible: remote_ctrl_visibleSelector(state),
    dragged_node_key: state.objects.dragged_node_key,
    draggedObjectFormData: state.objects.draggedObjectFormData,
    objectCreatorState: state.OBJECT_CREATOR.objectCreatorState,
    pattern: state.objects.pattern,
    qb_restrictions: state.QBUILDER.qb_restrictions,
    menuOpen: state.OBJECT_CREATOR.menuOpen,
    isObjectSelectorOpen: state.OBJECT_SELECTOR.isObjectSelectorOpen,
    isDevelopment: state.CONFIG.isDevelopment,
    isShowProfileView: isShowProfileViewSelector(state),
    isMobile: isMobileSelector(state),
    isShowNodeContent: isShowNodeContentSelector(state),
    // isShowMenuMobile: isShowMenuMobileSelector(state),
    configure_object: configure_objectSelector(state),
    objDetailsOpen: objDetailsOpenSelector(state),
  };
}

//------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
  return {
    getTreeByPattern: debounce(
      (pattern) => dispatch(getTreeByPattern(pattern)),
      500
    ),
    refreshTreeByPattern: () => dispatch(refreshTreeByPattern()),
    fetchObjects: (objId) => dispatch(fetchObjects(objId)),
    getFather: (objId) => dispatch(ObjectActionCreators.getFather(objId)),
    saveObjectFormData: (objId, objFormData) =>
      dispatch(ObjectActionCreators.saveObjectFormData(objId, objFormData)),
    remoteControllerShowToggle: (x, y) =>
      dispatch(remoteControllerShowToggle(x, y)),
    startDraggingNodes: (key) => dispatch(startDraggingNodes(key)),
    setDraggedObjFormData: (key) =>
      dispatch(ObjectActionCreators.setDraggedObjFormData(key)),
    setNodeDraggedOver: (objId) => dispatch(setNodeDraggedOver(objId)),
    setMousePos: (x, y) => dispatch(setMousePos(x, y)),

    selectObject: (objId) => dispatch(ObjectActionCreators.selectObject(objId)),
    openObjectDetails: () => dispatch(openObjectDetails()),
    closeObjectMenu: () => dispatch(ObjectActionCreators.closeObjectMenu()),
    closeSelectObject: () => dispatch(ObjectActionCreators.closeSelectObject()),
    onHiddenProfileView: () => dispatch(onHiddenProfileView()),
    onResetOptionMenu: () => dispatch(onResetOptionMenu()),
    getLocalPalette: (indexPalette, themeMode, palette) =>
      dispatch(getLocalPalette(indexPalette, themeMode, palette)),
    onShowSelectedNode: (status) => dispatch(onShowSelectedNode(status)),
    onResetSelectNode: (status) => dispatch(onResetSelectNode()),
    setShowNodeContent: (status) => dispatch(onSetShowNodeContent(status)),
    getProfiles: () => dispatch(getProfiles()),
    // setShowMenuMobile: (status) => dispatch(setShowMenuMobile(status)),
  };
};
//------------------------------------------------------------------------------------------------
export const NavigContainer = memo(
  withTranslation()(
    withAuth0(connect(mapStateToProps, mapDispatchToProps)(Navig))
  )
);
