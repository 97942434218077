import styled from 'styled-components';
import { devices } from '../../../../constants/devices';

//--------------------------------------------------------------------------------------------------------
export const DivObjFormGL = styled.div({
  color: 'black',
  fontFamily: 'proxima-nova',
  fontSize: '9px',
  fontStyle: 'normal',
  fontWeight: 600,
  height: '100%',
  letterSpacing: '0.5px',
  opacity: 1,
});

//--------------------------------------------------------------------------------------------------------
export const SpanLabelFormGL = styled.div({
  fontFamily: 'proxima-nova',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  letterSpacing: '0.5px',
  marginBottom: '15px',
  textTransform: 'uppercase',
  width: '100%',
  display: 'block',
});

export const ObjFormContainer = styled.div({
  width: '100%',
  backgroundColor: 'var(--ds-bg-base)',
});

export const ObjFormContainerContent = styled.div({
  borderBottom: `1px dashed var(--ds-border-base)`,
  color: '#406AB5',
  fontFamily: 'proxima-nova',
  fontSize: '23px',
  fontWeight: '550',
  height: '80px',
  opacity: '1',
  padding: '50px',
  paddingBottom: '0px',
  paddingLeft: '10px',
  paddingTop: '8px',
  position: '',
  top: '5px',
  width: 'calc(95% - 25px)',
});

export const ObjFormContainerContentError = styled.div({
  color: (props) => props?.color || '#EEE',
  fontSize: 10,
  fontWeight: 'normal',
  marginTop: '-7px',
  opacity: '1',
});

export const ObjFormContainerContentNotError = styled.div({
  border: '0px solid green',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  position: 'absolute',
  right: '30px',
  top: '60px',
});
export const ObjFormObjActionsMenuMantine = styled.div({
  position: 'absolute',
  right: '30px',
  top: '13px',
  [devices.tablet]: {
    right: '30px',
  },
});

export const ObjFormObjActionsMenuMantineIcon = styled.div({
  cursor: 'pointer',
  right: '60px',
  top: '13px',
  position: 'absolute',
  [devices.tablet]: {
    right: '60px',
  },
});

export const ObjFormStyles = {
  container: {
    backgroundColor: 'blue',
    width: '100%',
  },
  content: {
    backgroundColor: 'red',
    width: '100%',
  },
  tab: {
    height: '4px',
    marginBottom: '5px',
    marginLeft: '10px',
    width: '90%',
    border: '0px solid red',
  },
  tabMap: {},
  scrollArea: {
    height: 'calc(100vh - 245px)',
    width: 'calc(100% - 25px)',
  },
  contentScrollArea: {
    borderRadius: '5px',
    margin: '0px',
    marginTop: '0px',
    overflowX: 'hidden',
    paddingTop: '10px',
    borderRight: '1px solid #CCC',
  },
  item: {
    backgroundColor: 'red',
    width: '100%',
  },
};

export const OBJFormContent = styled.div({
  backgroundColor: 'var(--ds-bg-base)',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '5px',
  borderRadius: '5px',
  margin: '0px',
  marginTop: '0px',
  overflowX: 'hidden',
  paddingTop: '10px',
  // borderRight: '1px solid #CCC',
});

export const IconButtonObjForm = styled.div({
  cursor: 'pointer',
  width: 32,
  height: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const TitleObjForm = styled.div({
  color: (props) => props.color,
  [devices.mobileL]: {
    fontSize: 18,
  },
});
