import { splitPattern } from '../components/Utils/TextUtils';
import { parseJson } from './parseJson';

// ATTENTION CHANGER AUSSI DANS AIPERSPECTIVES.java
export const calculateGirlViewAdditionalColumn = ({
  typeOfficial,
  activePanelDataColumns,
  objectType,
  column,
}) => {
  let elemWidth = 120;
  let typeToUse = 'text';
  if (typeOfficial === 'date') {
    typeToUse = 'date';
    elemWidth = 100;
  }
  if (typeOfficial === 'number') {
    typeToUse = 'number';
    elemWidth = 90;
  }
  if (typeOfficial === 'area') {
    typeToUse = 'text';
    elemWidth = 250;
  }
  return [
    ...activePanelDataColumns,
    {
      to: objectType,
      data: column,
      w: elemWidth,
      type: typeToUse,
    },
  ];
};

export function getTodayDate() {
  const dateToday = new Date();
  dateToday.setHours(0);
  dateToday.setMilliseconds(0);
  dateToday.setMinutes(0);
  dateToday.setSeconds(0);
  return dateToday;
}
export const preParseDataOpenPerspective = ({ action, objId }) => {
  const { objFormData } = action;
  const filters1 = {};
  const pattern = objFormData.filter((item) => item.attrName === 'pattern')[0]
    .defaultValue;
  let collapsedNodes = parseJson(
    objFormData.filter((item) => item.attrName === 'collapsedNodes')[0]
      .defaultValue
  );
  if (collapsedNodes == null) collapsedNodes = [];
  let parts = splitPattern(pattern);
  parts.map((item, ind) => {
    filters1[`level${ind}`] = item;
    return null;
  });
  let res = {
    arrayClassifBy: parseJson(
      objFormData.filter((item) => item.attrName === 'arrayClassifBy')[0]
        .defaultValue
    ),
    arraySortBy: parseJson(
      objFormData.filter((item) => item.attrName === 'arraySortBy')[0]
        .defaultValue
    ),

    mandatoryLevel:
      objFormData.find((item) => item.attrName === 'mandatorylevel')
        ?.defaultValue ?? '0',

    arrowcolor:
      objFormData.find((item) => item.attrName === 'arrowcolor')
        ?.defaultValue ?? 'yellow',
    arrowtype:
      objFormData.find((item) => item.attrName === 'arrowtype')?.defaultValue ??
      'classic',
    arrowopacity:
      objFormData.find((item) => item.attrName === 'arrowopacity')
        ?.defaultValue ?? '0.6',
    arrowweight:
      objFormData.find((item) => item.attrName === 'arrowweight')
        ?.defaultValue ?? '1',
    collapsedNodes,

    displayMode: parseJson(
      objFormData.find((item) => item.attrName === 'displayMode').defaultValue
    ),
    dataColumns: parseJson(
      objFormData.filter((item) => item.attrName === 'dataColumns')[0]
        .defaultValue
    ),
    dataLines: parseJson(
      objFormData.filter((item) => item.attrName === 'dataLines')[0]
        .defaultValue
    ),
    filters: filters1,
    panels: parseJson(
      objFormData.filter((item) => item.attrName === 'panels')[0].defaultValue
    ),
    pattern,
    // perspectiveId: state.objId,
    perspectiveId: objId,

    perspectiveName: objFormData.filter((item) => item.attrName === 'name')[0]
      .defaultValue,
    restrictions: parseJson(
      objFormData.filter((item) => item.attrName === 'restrictions')[0]
        .defaultValue
    ),
  };
  res = fillMissingDefaultValues(res);
  return res;
};

function fillMissingDefaultValues(perspective) {
  let NUM_LEVEL = 5;
  for (let i = 0; i <= NUM_LEVEL; i++) {
    if (perspective.arrayClassifBy.length < NUM_LEVEL)
      perspective.arrayClassifBy.push('');
    if (perspective.arraySortBy.length < NUM_LEVEL)
      perspective.arraySortBy.push('');
    if (perspective.displayMode.length < NUM_LEVEL)
      perspective.displayMode.push('card');
    if (perspective.panels.length < NUM_LEVEL)
      perspective.panels.push({ width: 300 });
    if (perspective.dataLines.length < NUM_LEVEL)
      perspective.dataLines.push([]);
    if (perspective.dataColumns.length < NUM_LEVEL)
      perspective.dataColumns.push([]);
  }
  return perspective;
}

export function returnJSON(arrayObj) {
  const res = {};
  const objFormData =
    arrayObj.objFormData != undefined ? arrayObj.objFormData : arrayObj;
  if (objFormData != undefined) {
    for (const obj in objFormData) {
      res[objFormData[obj].attrName] = objFormData[obj].value;
    }
  }
  if (arrayObj.layers != undefined) {
    res.layers = [...arrayObj.layers];
  }
  return res;
}

//----------------------------------------------------------------------
export function replaceObj(nodes, newObj, key) {
  return nodes.map(
    ((newObj, key, item) => {
      if (item.children != null)
        item.children = replaceObj(item.children, newObj, key);
      return item.key === key ? { ...item, ...returnJSON(newObj) } : item;
    }).bind(null, newObj, key)
  );
}

export function extractDicoFormObjFormData(objFormData, result) {
  let res = result;
  if (res == null) {
    res = { ROOT: 'HOME' };
  }
  for (const itemInd in objFormData) {
    const item = objFormData[itemInd];
    const { list } = item;

    for (const elemInd in list) {
      const elem = list[elemInd];
      res[elem.key] = elem.name;
    }
  }

  for (const itemInd in objFormData) {
    const item = objFormData[itemInd];
    const list = item.options;
    if (list && list.indexOf('[') === 0) {
      try {
        const list = parseJson(item.options);

        for (const elemInd in list) {
          const elem = list[elemInd];
          res[elem.value] = elem.display;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  return res;
}

export function extractDicoFullFormObjFormData(objFormData, res = {}) {
  for (const itemInd in objFormData) {
    const item = objFormData[itemInd];
    const list = item.options;
    if (list && list.indexOf('[') === 0) {
      try {
        const lstJSON = parseJson(list);
        for (const elemInd in lstJSON) {
          const elem = lstJSON[elemInd];
          res[elem.value] = { ...elem };
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  return res;
}

export function retrieveObj(nodes, key) {
  let res = null;
  let i = 0;

  while (res == null && i < nodes.length) {
    const node = nodes[i];
    if (node.key === key) {
      res = node;
    } else if (node.children) {
      res = retrieveObj(node.children, key);
    }
    i++;
  }
  return res;
}

export const prepareParamsCurrentPerspective = (state) => {
  const params = {
    pattern: state.objects.pattern,
    qbrestrictions: JSON.stringify(state.QBUILDER.qb_restrictions),
    restrictions: JSON.stringify(state.objects.restrictions),
    dataColumns: JSON.stringify(state.objects.dataColumns),
    dataLines: JSON.stringify(state.objects.dataLines),
    arrayClassifBy: JSON.stringify(state.objects.arrayClassifBy),
    arraySortBy: JSON.stringify(state.objects.arraySortBy),
    displayMode: JSON.stringify(state.objects.displayMode),
    mandatorylevel: JSON.stringify(state.objects.mandatoryLevel),
    arrayStatBy: JSON.stringify(state.objects.arrayStatBy),
    objId: state.objects.perspectiveId,
    filters: JSON.stringify(state.objects.filters),
    panels: JSON.stringify(state.objects.panels),
    collapsedNodes: JSON.stringify(state.objects.collapsedNodes),
  };
  return params;
};
