export const layout = {
  bands_config: [
    { width: 500, minWidth: 300 },
    { width: 800, minWidth: 300 },
    { width: 500, minWidth: 300 },
    { width: 500, minWidth: 300 },
    { width: 1000, minWidth: 500 },
  ],
};

export const NODE_NAVIG_WIDTH = 260;
