import { ActionIcon, useMantineColorScheme } from '@mantine/core';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MoonIcon, SunIcon } from '../../constants/icon';
import { setModeTheme } from '../../reducers/themeReducers/action';
import { isDarkModeSelector } from '../../reducers/themeReducers/themeSelector';

export function ToggleTheme() {
  const dispatch = useDispatch();
  const isDarkMode = useSelector(isDarkModeSelector);

  const onToggleColorScheme = useCallback(() => {
    dispatch(setModeTheme(isDarkMode ? 'light' : 'dark'));
  }, [isDarkMode]);

  return (
    <ActionIcon
      styles={ToggleThemeStyles.styles}
      onClick={onToggleColorScheme}
      onTouchEnd={onToggleColorScheme}
      style={ToggleThemeStyles.style}
      variant="transparent"
    >
      {isDarkMode ? <SunIcon /> : <MoonIcon />}
    </ActionIcon>
  );
}

const ToggleThemeStyles = {
  styles: {
    root: {
      transform: 'translateY(0px) !important',
      '&hover': {
        backgroundColor: 'pink',
      },
    },
  },
  style: {
    borderRadius: 30,
    padding: 4,
    width: 30,
    height: 30,
  },
};
