export const segmentedControlStyles = () => ({
  label: {
    color: `var(--ds-text-base) !important`,
    '&:hover': {
      color: `var(--ds-text-active)  !important`,
    },
  },
  labelActive: {
    color: `var(--ds-text-base)  !important`,
    '&:hover': {
      color: `var(--ds-text-active)  !important`,
    },
  },
  root: {
    backgroundColor: `var(--ds-bg-secondary)  !important`,
  },
  active: {
    backgroundColor: `var(--ds-bg-base)  !important`,
  },
});
