import {
  ActionIcon,
  Badge,
  Button,
  Center,
  Group,
  Modal,
  Pagination,
  Space,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { PlaylistAdd, SquarePlus, Upload, X } from 'tabler-icons-react';

import { useEventListener } from '@mantine/hooks';
import { cloneDeep, isArray, isEmpty } from 'lodash';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ObjectApi from '../../api/ObjectApi';
import { GalleryIcon } from '../../constants/icon';
import { getIconColor } from '../UI/Objects/ObjFieldDrawingPad/DropFileObjDrawingPad';
import { ButtonCategory } from './CategoriesLibraryIcons';
import {
  ModalDropZoneAddTagContainer,
  ModalDropZoneButtonAddTag,
  ModalDropZoneButtonUploadIcon,
  ModalDropZoneGalleryIcon,
  ModalDropZonePreviewImage,
  modalDropZoneIconStyle,
} from './ModalLIbraryIconCss';
import { modalStyle } from '../../constants/commonStyles';

const initialAccessInfo = {
  files: null,
};
export default function ModalAddResource({ type, onSave, multiple }) {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const openRef = useRef();
  const { t } = useTranslation();
  const [accessInfo, setAccessInfo] = useState(initialAccessInfo);
  const [activeImage, setActiveImage] = useState(0);
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);

  const disableButtonSave = useMemo(() => {
    if (type === 'add_category') {
      return isEmpty(accessInfo.files) || !accessInfo.files[0].newName;
    }
    return isEmpty(accessInfo.files);
  }, [type, accessInfo]);

  const ref = useEventListener('keyup', (e) => {
    if (disableButtonSave || isLoadingUploadFile || isLoadingSaveData) return;
    if (e.code === 'Enter') {
      const newData = cloneDeep(accessInfo);

      if (isEmpty(newData.files[activeImage]?.tags)) {
        newData.files[activeImage].tags = [];
      }
      newData.files[activeImage].tags.push(e.target.value);

      setAccessInfo({ ...newData });

      e.target.value = '';
    }
  });

  const handleUploadFile = async (e) => {
    try {
      let promiseFileUpload = [];
      setIsLoadingUploadFile(true);
      accessInfo.files.map((file) => {
        promiseFileUpload.push(ObjectApi.saveLibraryAsset(file, file?.name));
      });
      return Promise.all(promiseFileUpload).then((urls) => {
        setIsLoadingUploadFile(false);
        urls?.map((url, index) => {
          const { tags } = accessInfo.files[index];
          const name = accessInfo.files[index]?.newName;
          url.tags = tags;
          url.name = name;
        });
        return urls;
      });
    } catch (error) {
      console.log(
        '🚀 ~ file: ModalAddResource.js:87 ~ handleUploadFile ~ error:',
        error
      );

      setIsLoadingUploadFile(false);
    }
  };
  useEffect(() => {
    if (!isEmpty(accessInfo.files) && type !== 'add_category') {
      setAccessInfo({
        ...accessInfo,
        // name: removeFileExtension(accessInfo?.access?.name),
      });
    }
  }, [accessInfo.files, type]);

  const styleDisableInput = {
    backgroundColor: `${
      theme.colorScheme === 'dark'
        ? theme.colors.blue_gray[6]
        : theme.colors.blue_gray[2]
    } !important`,
  };

  const onClickAddTag = useCallback(() => {
    if (isLoadingSaveData || isLoadingUploadFile) return;

    const newData = cloneDeep(accessInfo);
    newData.files[activeImage].tags.push(
      document.getElementById('input_tags').value
    );
    setAccessInfo(newData);

    document.getElementById('input_tags').value = '';
  }, [accessInfo, disableButtonSave, activeImage]);

  const onRemoveItemTag = (tag) => {
    if (isLoadingSaveData || isLoadingUploadFile) return;

    const newData = cloneDeep(accessInfo);
    const tagsAvailable = newData.files[activeImage].tags.filter(
      (item) => item !== tag
    );
    newData.files[activeImage].tags = tagsAvailable;
    setAccessInfo(newData);
  };

  const onOpenModal = () => {
    setOpened(true);
  };

  const onSaveData = async () => {
    const urlsImage = await handleUploadFile();
    setIsLoadingSaveData(true);
    onSave({ urlsImage }, () => {
      setAccessInfo(initialAccessInfo);
      setOpened(false);
      setIsLoadingSaveData(false);
    });
  };

  return (
    <>
      <Modal
        zIndex={20000000007}
        opened={opened}
        onClose={() => {
          setOpened(false);
          setAccessInfo(initialAccessInfo);
        }}
        title={t(type === 'add_category' ? 'add_category' : 'add_assets')}
        styles={modalStyle}
      >
        <Dropzone
          multiple={multiple}
          disabled={isLoadingUploadFile}
          onDrop={(files) => {
            setActiveImage(0);
            const newFileWithTags = files.map((item) => {
              if (multiple) {
                item.tags = [];
                item.tags.push(removeFileExtension(item.name));
              }
              item.newName = multiple ? item.name : '';
              return item;
            });
            setAccessInfo({ ...initialAccessInfo, files: newFileWithTags });
          }}
          accept={[MIME_TYPES.svg, MIME_TYPES.png, MIME_TYPES.jpeg]}
          openRef={openRef}
          styles={{
            root: {
              ...modalDropZoneIconStyle,
              ':hover': {
                cursor: isLoadingUploadFile ? 'no-drop' : 'pointer',
                ':hover': {
                  backgroundColor: 'var(--ds-bg-secondary)',
                },
              },
            },
          }}
        >
          {(status) =>
            dropzoneChildren(status, theme, accessInfo.files, activeImage)
          }
        </Dropzone>
        <Space h="sm" />
        {accessInfo.files?.length > 1 && (
          <Center>
            <Pagination
              onChange={(index) => {
                setActiveImage(index - 1);
              }}
              total={accessInfo.files?.length}
              size="xs"
            />
          </Center>
        )}

        <Space h="lg" />

        <TextInput
          styles={{
            disabled: {
              ...styleDisableInput,
            },
          }}
          disabled={
            isEmpty(accessInfo.files) ||
            isLoadingSaveData ||
            isLoadingUploadFile
          }
          placeholder="Perspective"
          label={t('name')}
          required
          value={
            isEmpty(accessInfo?.files)
              ? ''
              : accessInfo?.files[activeImage]?.newName
          }
          onChange={(event) => {
            const newData = cloneDeep(accessInfo);
            newData.files[activeImage].newName = event.target.value;
            setAccessInfo({ ...newData });
          }}
        />
        <Space h="md" />

        {type !== 'add_category' && (
          <>
            <TextInput
              id="input_tags"
              styles={{
                disabled: {
                  ...styleDisableInput,
                },
              }}
              disabled={
                isEmpty(accessInfo.files) ||
                isLoadingSaveData ||
                isLoadingUploadFile
              }
              placeholder={t('enter_add_tag')}
              label={t('tags')}
              required
              ref={ref}
              rightSection={
                <ActionIcon onClick={onClickAddTag}>
                  <ModalDropZoneButtonAddTag>
                    <PlaylistAdd />
                  </ModalDropZoneButtonAddTag>
                </ActionIcon>
              }
            />
            <Space h="md" />
            <ModalDropZoneAddTagContainer>
              {isArray(accessInfo.files) &&
                accessInfo.files[activeImage]?.tags.map((tag) => (
                  <Badge
                    key={tag}
                    color="blue"
                    variant="filled"
                    rightSection={
                      <ActionIcon
                        size="xs"
                        radius="xl"
                        variant="transparent"
                        onClick={() => onRemoveItemTag(tag)}
                      >
                        <X color="red" size={10} />
                      </ActionIcon>
                    }
                  >
                    {tag}
                  </Badge>
                ))}
            </ModalDropZoneAddTagContainer>
          </>
        )}

        <Space h="xl" />

        <Button
          styles={{
            root: {
              width: '100%',
            },
          }}
          loaderPosition="right"
          loading={isLoadingUploadFile || isLoadingSaveData}
          disabled={disableButtonSave || isLoadingSaveData}
          onClick={onSaveData}
        >
          {t('app.save')}
        </Button>
      </Modal>
      {type === 'add_category' ? (
        <ButtonCategory
          type="add_category"
          onClick={onOpenModal}
          buttonStyle={{
            border: `1px solid ${
              theme.colorScheme === 'dark'
                ? theme.colors.blue_gray[6]
                : theme.colors.indigo[2]
            }`,
          }}
        >
          <SquarePlus />
          <Text size="sm">{t('add_category')}</Text>
        </ButtonCategory>
      ) : (
        <ModalDropZoneGalleryIcon>
          <ActionIcon
            styles={{
              root: {
                width: 82,
                height: 82,
                border: '1px dashed #CBD5E1',
                ':hover': {
                  backgroundColor:
                    theme.colorScheme === 'dark'
                      ? theme.colors.blue_gray[8]
                      : theme.colors.blue_gray[2],
                },
              },
            }}
            // onClick={handleOpenModal}
            onClick={onOpenModal}
            variant="outline"
          >
            <GalleryIcon />
          </ActionIcon>
        </ModalDropZoneGalleryIcon>
      )}
    </>
  );
}
const PreViewImage = memo(({ file }) => {
  return <ModalDropZonePreviewImage file={file} />;
});

const dropzoneChildren = (status, theme, file, activeImage) => {
  return (
    <Group
      position="center"
      spacing="xl"
      style={{
        pointerEvents: 'none',
        maxHeight: '100%',
        maxWidth: '100%',
        padding: 20,
      }}
    >
      {file ? (
        <PreViewImage file={file[activeImage]} />
      ) : (
        <ModalDropZoneButtonUploadIcon>
          <ImageUploadIcon
            status={status}
            style={{ color: getIconColor(status, theme) }}
            size={48}
          />
        </ModalDropZoneButtonUploadIcon>
      )}
    </Group>
  );
};
function ImageUploadIcon({ status, ...props }) {
  if (status.accepted) {
    return <Upload {...props} />;
  }

  if (status.rejected) {
    return <X {...props} />;
  }

  return <GalleryIcon {...props} />;
}
function removeFileExtension(filename) {
  return filename.replace(/\.[^/.]+$/, '');
}
