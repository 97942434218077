import { getObjectTypeByLabel } from '../Utils/NodeUtils';
import { splitPattern } from '../Utils/TextUtils';

const getStepForArray = (arrayFields) => {
  let i = 0;
  while (i < arrayFields.length && arrayFields[i].value) i++;
  return i;
};
//------------------------------------------------------------------------------------------------------------------
export const getStep = (obj) => {
  const array = [
    { value: obj.typeNewObject },
    { value: obj.typeParent },
    { value: obj.idParent },
  ];
  return getStepForArray(array);
};

//------------------------------------------------------------------------------------------------------------------
export const getSuggestedType = (pattern, typeParent, objectsDefList) => {
  let suggestedType = getObjectTypeByLabel('perspective', objectsDefList); // by default
  let patternArray = splitPattern(pattern);
  if (patternArray[0] && typeParent && typeParent.startsWith(patternArray[0]))
    suggestedType = patternArray[1];
  if (patternArray[1] && typeParent && typeParent.startsWith(patternArray[1]))
    suggestedType = patternArray[2];
  return suggestedType;
};

//------------------------------------------------------------------------------------------------------------------
export const getUserAudiences = (audiences, userPriv) => {
  return audiences.filter((a) => userPriv[a.key] != null);
};

//------------------------------------------------------------------------------------------------------------------
