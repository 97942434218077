import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PhotosIcon } from '../../../constants/icon';
import {
  changeModeLibrary,
  changeStatusShowModalIconsLibrary,
} from '../../../reducers/iconsLibraryReducer/action';
import { currentPaletteActiveSelector } from '../../../reducers/themeReducers/themeSelector';
import {
  DivFloatingButton,
  WrapIconFloatingButton,
} from '../../Main/appStyles';
import { TooltipButton } from '../VerticalButtonBar';
import { MODE_LIBRARY_ICON } from '../../../reducers/iconsLibraryReducer/type';

export default function ButtonShowIconsLibrary() {
  const pal = useSelector(currentPaletteActiveSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onShowModal = useCallback(() => {
    dispatch(changeStatusShowModalIconsLibrary(true));
    dispatch(changeModeLibrary(MODE_LIBRARY_ICON.VIEW_AND_ADD_ICON));
  }, []);

  return (
    <TooltipButton label={t('library_icons')}>
      <DivFloatingButton
        pal={pal}
        onClick={onShowModal}
        onTouchEnd={onShowModal}
      >
        <WrapIconFloatingButton>
          <PhotosIcon />
        </WrapIconFloatingButton>
      </DivFloatingButton>
    </TooltipButton>
  );
}
