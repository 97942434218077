import { Button, ColorInput, Modal, Space, Switch } from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colorsSvgDrawing } from '../../../../constants/colors';
import {
  getPolylineBackground,
  isPolylineClosed,
} from '../../../../utils/generatePolyline';
import { modalStyle } from '../../../../constants/commonStyles';

export default function EditPolylineAndArrowLineSvgBox({
  visible,
  onHideModal,
  currentElementSelect,
  onUpdatePolylineOrArrowLine,
}) {
  const { t } = useTranslation();
  const [isTransparentBackground, setIsTransparentBackground] = useState(false);
  const [svgInfo, setSvgInfo] = useState(null);
  const isClosedPolyline = isPolylineClosed(svgInfo?.points);

  const backgroundColor = isTransparentBackground
    ? 'transparent'
    : svgInfo?.backgroundColor || 'none';

  const onUpdate = useCallback(() => {
    onUpdatePolylineOrArrowLine({
      ...svgInfo,
      ...(svgInfo?.type === 'polyline'
        ? {
            backgroundColor,
          }
        : {}),
    });
    onHideModal(false);
  }, [svgInfo, backgroundColor]);

  useEffect(() => {
    setSvgInfo(currentElementSelect);
  }, [currentElementSelect]);

  const onChangeValue = (key, value) => {
    setSvgInfo({ ...svgInfo, [key]: value });
  };

  return (
    <>
      <Modal
        styles={modalStyle}
        opened={visible}
        onClose={onHideModal}
        title={'Edit SVG'}
        centered
        overlayOpacity={0.55}
        overlayBlur={3}
        size={'md'}
      >
        <ColorInput
          value={svgInfo?.color}
          placeholder="Pick color"
          label="Line color"
          disallowInput
          withPicker={false}
          swatches={colorsSvgDrawing}
          onChange={(color) => {
            onChangeValue('color', color);
          }}
        />
        {svgInfo?.type === 'polyline' && isClosedPolyline && (
          <>
            <Space h="md" />
            <ColorInput
              value={backgroundColor}
              placeholder="Pick color"
              label="Background color"
              swatches={colorsSvgDrawing}
              withPicker={false}
              disallowInput
              onChange={(color) => onChangeValue('backgroundColor', color)}
            />
            <Space h="md" />
            <Switch
              label="Background transparent"
              checked={isTransparentBackground}
              onChange={(event) =>
                setIsTransparentBackground(event.currentTarget.checked)
              }
            />
          </>
        )}
        <Space h="md" />

        {visible && (
          <Container>
            {svgInfo?.type === 'polyline' ? (
              <svg width={320} height={260} viewBox="0 0 790 640">
                <polyline
                  strokeWidth={svgInfo?.tick}
                  points={onGeneratePolylinePath(svgInfo?.points)}
                  stroke={svgInfo?.color}
                  fill={getPolylineBackground(svgInfo?.points, backgroundColor)}
                />
              </svg>
            ) : (
              <svg width={320} height={260} viewBox="0 0 790 640">
                <defs>
                  <marker
                    id={`${svgInfo.key}-edit`}
                    viewBox="0 -5 10 10"
                    refX="2"
                    refY="0"
                    markerUnits="strokeWidth"
                    markerWidth="3"
                    markerHeight="3"
                    orient="auto"
                    d="M0,-5L10,0L0,5"
                    strokeWidth={svgInfo?.tick}
                  >
                    <path d="M0,-5L10,0L0,5" fill={svgInfo?.color} />
                  </marker>
                </defs>
                <svg xmlns="http://www.w3.org/2000/svg">
                  <line
                    x1={svgInfo?.points?.[0].x}
                    y1={svgInfo?.points?.[0].y}
                    x2={svgInfo?.points?.[1].x}
                    y2={svgInfo?.points?.[1].y}
                    stroke={svgInfo?.color}
                    markerEnd={`url(#${svgInfo.key}-edit)`}
                    strokeWidth={svgInfo?.tick}
                  />
                </svg>
              </svg>
            )}
          </Container>
        )}

        <Space h="md" />
        <Button onClick={onUpdate}>{t('update')}</Button>
      </Modal>
    </>
  );
}

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const onGeneratePolylinePath = (points) => {
  let pathPolyline = '';

  points?.map((point) => {
    pathPolyline += `${point.x},${point.y} `;
  });
  return pathPolyline;
};
