export const updateCSSVariables = (pal) => {
  const variables = {
    '--ds-base': pal.dsBase,
    '--ds-base60': pal.dsBase60,
    '--ds-base80': pal.dsBase80,
    '--ds-base-intense': pal.dsBase80,

    '--ds-bg-base': pal.dsBgBase,
    '--ds-bg-base-60': pal.dsBgBase60,
    '--ds-bg-base-80': pal.dsBgBase80,
    '--ds-bg-lower': pal.dsBgLower,
    '--ds-bg-overlay': pal.dsBgOverlay,
    '--ds-bg-secondary': pal.dsBgSecondary,

    '--ds-border-base': pal.dsBorderBase,
    '--ds-border-intense': pal.dsBorderIntense,

    '--ds-icon-active': pal.dsIconActive,
    '--ds-icon-base': pal.dsIconBase,
    '--ds-icon-secondary': pal.dsIconSecondary,

    '--ds-text-active': pal.dsTextActive,
    '--ds-text-base': pal.dsTextBase,
    '--ds-text-invert': pal.dsTextInvert,
    '--ds-text-muted': pal.dsTextMuted,
    '--ds-text-secondary': pal.dsTextSecondary,
    '--ds-text-title': pal.dsTextTitle,
    '--ds-text-white': pal.dsTextWhite,

    '--dsc-icon-active': pal.dscIconActive,
    '--dsc-icon-base': pal.dscIconBase,
    '--dsc-text-active': pal.dscTextActive,
    '--dsc-text-base': pal.dscTextBase,
    '--dsc-text-secondary': pal.dscTextSecondary,
    '--dsc-bg-base': pal.dscBgBase,
    '--dsc-bg-secondary': pal.dscBgSecondary,
  };
  const root = document.documentElement;
  Object.keys(variables).forEach((key) => {
    root.style.setProperty(key, variables[key]);
  });
};

export const shadows =
  'box-shadow: 0px 15px 25px -4px rgba(150, 150, 150, 0.24)';

export const modalStyle = {
  modal: {
    backgroundColor: 'var(--ds-bg-base)',
  },
  title: {
    color: 'var(--ds-text-title)',
    fontWeight: 600,
  },
  body: {
    paddingBottom: 12,
  },
};

export const menuStyle = {
  body: {
    backgroundColor: 'var(--ds-bg-base)',
  },
  itemLabel: {
    color: 'var(--ds-text-base)',
  },
  itemIcon: {
    color: 'var(--ds-base)',
  },
  itemHovered: {
    backgroundColor: 'var(--ds-bg-secondary)',
  },
};
