import { getHost } from '../helper/getHost';
import { onPushAndAutoRemoveNotification } from '../reducers/notificationReducer/action';

// websocketMiddleware.js
export const websocketMiddleware = () => {
  let socket;
  const url = `wss://${getHost()}/guerillaPM3/ws`;

  return ({ dispatch }) =>
    (next) =>
    (action) => {
      switch (action.type) {
        // Action to initiate WebSocket connection
        case 'WEBSOCKET_CONNECT':
          try {
            socket = new WebSocket(url);
          } catch (error) {
            alert(error);
          }

          // Set up WebSocket event handlers
          socket.onopen = () => {
            dispatch({ type: 'WEBSOCKET_OPEN' });
          };

          socket.onmessage = (event) => {
            const message = JSON.parse(event.data);

            // Dispatch an action when a message is received from the server
            dispatch({ type: 'SERVER_MESSAGE', payload: message });
            dispatch(
              onPushAndAutoRemoveNotification({
                title: `server message:${message.type}`,
                description: message.payload,
                time: 15000,
              })
            );
          };

          socket.onclose = () => {
            dispatch({ type: 'WEBSOCKET_CLOSE' });
            setTimeout(() => dispatch({ type: 'WEBSOCKET_CONNECT' }), 1000);
          };

          socket.onerror = (error) => {
            dispatch({ type: 'WEBSOCKET_ERROR', payload: error });
          };
          break;

        // Action to send a message via WebSocket
        case 'WEBSOCKET_SEND':
          if (socket) {
            socket.send(JSON.stringify(action.payload));
          }
          break;

        // Pass all other actions through middleware
        default:
          return next(action);
      }
    };
};
