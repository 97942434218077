import {
  Button,
  Group,
  Modal,
  Space,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { Upload, X } from 'tabler-icons-react';

import { cloneDeep, isEmpty } from 'lodash';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ObjectApi from '../../api/ObjectApi';
import { GalleryIcon } from '../../constants/icon';
import { getIconColor } from '../UI/Objects/ObjFieldDrawingPad/DropFileObjDrawingPad';
import {
  ModalDropZoneButtonUploadIcon,
  ModalDropZonePreviewImage,
  modalDropZoneIconStyle,
} from './ModalLIbraryIconCss';
import { modalStyle } from '../../constants/commonStyles';

const initialAccessInfo = {
  file: null,
  url: null,
  categoryName: '',
};
export default function ModalUpdateResource({
  getDataImageUploadAndUpdateCategory,
  opened,
  defaultValue,
  onCloseModal,
}) {
  const theme = useMantineTheme();
  const openRef = useRef();
  const { t } = useTranslation();
  const [accessInfo, setAccessInfo] = useState(initialAccessInfo);
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);

  const isHaveNewFile = !isEmpty(accessInfo.file);

  useEffect(() => {
    if (opened) setAccessInfo(defaultValue);
  }, [defaultValue, opened]);

  const handleUploadFile = async (e) => {
    try {
      setIsLoadingUploadFile(true);
      const urlImage = await ObjectApi.saveLibraryAsset(
        accessInfo.file,
        accessInfo.file?.name
      );
      setIsLoadingUploadFile(false);
      return urlImage;
    } catch (error) {
      console.log(
        '🚀 ~ file: ModalAddResource.js:87 ~ handleUploadFile ~ error:',
        error
      );

      setIsLoadingUploadFile(false);
    }
  };

  const styleDisableInput = {
    backgroundColor: `${
      theme.colorScheme === 'dark'
        ? theme.colors.blue_gray[6]
        : theme.colors.blue_gray[2]
    } !important`,
  };

  const handleUpdateData = async () => {
    if (isHaveNewFile) {
      console.log('handle change icon');
      const urlsImage = await handleUploadFile();
      setIsLoadingSaveData(true);
      getDataImageUploadAndUpdateCategory({
        url: accessInfo.url,
        newUrl: urlsImage?.filepath,
        name: accessInfo.categoryName,
        disableLoading: () => setIsLoadingSaveData(false),
      });
      return;
    }
    console.log('only change name');

    setIsLoadingSaveData(true);
    getDataImageUploadAndUpdateCategory({
      url: accessInfo.url,
      name: accessInfo.categoryName,
      disableLoading: () => setIsLoadingSaveData(false),
    });

    // onSave({ urlsImage }, () => {
    //   setAccessInfo(initialAccessInfo);
    //   setIsLoadingSaveData(false);
    // });
  };

  return (
    <>
      <Modal
        zIndex={20000000007}
        opened={opened}
        onClose={() => {
          if (typeof onCloseModal === 'function') onCloseModal();
          setAccessInfo(initialAccessInfo);
        }}
        title={t('edit_category')}
        styles={modalStyle}
      >
        <Dropzone
          multiple={false}
          disabled={isLoadingUploadFile}
          onDrop={(files) => {
            setAccessInfo({
              file: files[0],
              categoryName: accessInfo?.categoryName,
            });
          }}
          accept={[MIME_TYPES.svg, MIME_TYPES.png, MIME_TYPES.jpeg]}
          openRef={openRef}
          styles={{
            root: {
              ...modalDropZoneIconStyle,
              ':hover': {
                cursor: isLoadingUploadFile ? 'no-drop' : 'pointer',
                ':hover': {
                  backgroundColor: 'var(--ds-bg-secondary)',
                },
              },
            },
          }}
        >
          {(status) =>
            dropzoneChildren(
              status,
              theme,
              accessInfo.file,
              accessInfo?.url,
              isHaveNewFile
            )
          }
        </Dropzone>
        <Space h="sm" />

        <TextInput
          styles={{
            disabled: {
              ...styleDisableInput,
            },
          }}
          disabled={isLoadingSaveData || isLoadingUploadFile}
          placeholder="Perspective"
          label={t('name')}
          required
          value={accessInfo?.categoryName}
          onChange={(event) => {
            const newData = cloneDeep(accessInfo);
            newData.categoryName = event.target.value;
            setAccessInfo(newData);
          }}
        />
        <Space h="md" />

        <Space h="xl" />

        <Button
          styles={{
            root: {
              width: '100%',
            },
          }}
          loaderPosition="right"
          loading={isLoadingUploadFile || isLoadingSaveData}
          disabled={isLoadingSaveData}
          onClick={handleUpdateData}
        >
          {t('update')}
        </Button>
      </Modal>
    </>
  );
}
const PreViewImage = memo(({ file, isHaveNewFile }) => {
  console.log('filefile', file);
  console.log('isHaveNewFile', isHaveNewFile);
  return (
    <ModalDropZonePreviewImage
      id="ModalDropZonePreviewImage"
      file={file}
      isHaveNewFile={isHaveNewFile}
    />
  );
});

const dropzoneChildren = (status, theme, file, previewUrl, isHaveNewFile) => {
  return (
    <Group
      position="center"
      spacing="xl"
      style={{
        pointerEvents: 'none',
        maxHeight: '100%',
        maxWidth: '100%',
        padding: 20,
      }}
    >
      {file || previewUrl ? (
        <PreViewImage
          file={isEmpty(file) ? previewUrl : file}
          isHaveNewFile={isHaveNewFile}
        />
      ) : (
        <ModalDropZoneButtonUploadIcon>
          <ImageUploadIcon
            status={status}
            style={{ color: getIconColor(status, theme) }}
            size={48}
          />
        </ModalDropZoneButtonUploadIcon>
      )}
    </Group>
  );
};
function ImageUploadIcon({ status, ...props }) {
  if (status.accepted) {
    return <Upload {...props} />;
  }

  if (status.rejected) {
    return <X {...props} />;
  }

  return <GalleryIcon {...props} />;
}
