import { Modal, Space, Stepper } from '@mantine/core';
import { debounce, isEmpty } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ObjectApi from '../../api/ObjectApi';
import { setStatusModalImportFile } from '../../reducers/layoutReducer/action';
import { isShowModalImportMappingFileSelector } from '../../reducers/layoutReducer/layoutSelector';
import { onPushAndAutoRemoveNotification } from '../../reducers/notificationReducer/action';
import { objectsDefListSelector } from '../../reducers/objectReducers/objectSelector';
import { delayMilliseconds } from '../../utils/delayMilliseconds';
import { heightScreen } from '../../utils/windowSize';
import FieldTab from './FieldTab';
import FinishTabMapping from './FinishTabMapping';
import { stepperModalImportExcelStyles } from './ModalImportExcelCSS';
import UpFileTab from './UpFileTab';
import { modalStyle } from '../../constants/commonStyles';

export default function ModalMappingFile() {
  const isShowModal = useSelector(isShowModalImportMappingFileSelector);
  const dispatch = useDispatch();
  const objFormData = useSelector(objectsDefListSelector);
  const [isLoadingLoadDataExcel, setLoadingLoadDataExcel] = useState(false);

  const [opened, setOpened] = useState({});
  const [checked, setChecked] = useState({});
  const [columnObjectsDefListImport, setColumnObjectsDefListImport] = useState(
    {}
  );

  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [responseUpFile, setResponseUpFile] = useState(null);
  const [active, setActive] = useState(0);
  const [activePage, setPage] = useState(1);
  const savedFileReference = useRef(null);

  useEffect(() => {
    setPage(1);
  }, [file]);

  const resetState = () => {
    setFile(null);
    setValueSelects('');
    setActive(0);
    setResponseUpFile(null);
    setIsLoading(false);
    setOpened({});
    setChecked({});
    setColumnObjectsDefListImport({});
    setPage(1);
  };

  const nextStep = () => {
    setActive((current) => (current < 3 ? current + 1 : current));
  };

  const prevStep = useCallback(() => {
    setActive(active - 1);
  }, [active]);

  useEffect(() => {
    if (active === 0 && !isEmpty(columnObjectsDefListImport)) {
      setActive(0);
      setResponseUpFile(null);
      setOpened({});
      setChecked({});
      setColumnObjectsDefListImport({});
      setPage(1);
    }
  }, [active, columnObjectsDefListImport]);

  const closeModal = () => {
    resetState();
    dispatch(setStatusModalImportFile(false));
  };

  const [valueSelect, setValueSelects] = useState('');

  const specificObjectConfigure = useMemo(() => {
    return objFormData.find((data) => data.key === valueSelect);
  }, [valueSelect]);

  const mappingData = useMemo(() => {
    let data = specificObjectConfigure?.objFormData.reduce(
      (accumulator, currentValue) => {
        accumulator.targetType.fields.push(currentValue.attrName);
        return accumulator;
      },
      {
        targetType: {
          fields: [],
          id: valueSelect,
        },
        mappings: [],
      }
    );
    Object.entries(columnObjectsDefListImport).map(([key, value]) => {
      if (value) {
        data.mappings.push({
          sourceColumn: value,
          targetField: key,
          operations: [
            ...(checked[key]?.uppercase ? ['uppercase'] : []),
            ...(checked[key]?.capitalize ? ['capitalize'] : []),
          ],
        });
      }
    });
    return data;
  }, [specificObjectConfigure, columnObjectsDefListImport, checked]);

  const onGetPreviewFromExcelFile = useCallback(
    async (mappingData, savedFileReference, activePage, valueSelect) => {
      try {
        console.log('mappingData', mappingData);
        const response = await ObjectApi.getPreviewFromExcelFile(
          mappingData,
          savedFileReference,
          activePage - 1,
          valueSelect
        );
        console.log('mappingData response', response);
      } catch (error) {}
    },
    []
  );

  const throttled = useRef(
    debounce(
      (mappingData, savedFileReference, activePage, valueSelect) =>
        onGetPreviewFromExcelFile(
          mappingData,
          savedFileReference,
          activePage,
          valueSelect
        ),
      250
    )
  );

  useEffect(() => {
    if (
      active === 1 &&
      savedFileReference.current &&
      !isEmpty(mappingData?.mappings)
    ) {
      throttled.current(
        mappingData,
        savedFileReference.current,
        activePage,
        valueSelect
      );
    }
  }, [mappingData, activePage, valueSelect, active]);

  const getDataFromExcel = useCallback(
    async (page) => {
      if (isLoadingLoadDataExcel) return;
      setLoadingLoadDataExcel(true);
      try {
        const response = await ObjectApi.getExtractFromExcelFile(
          file,
          file?.name,
          page - 1
        );
        setResponseUpFile(response);
        savedFileReference.current = response?.savedFileReference;
        setLoadingLoadDataExcel(false);
      } catch (error) {
        setLoadingLoadDataExcel(false);
      }
    },
    [file, isLoadingLoadDataExcel]
  );

  const handleNextStepUpFile = useCallback(async () => {
    try {
      setIsLoading(true);
      await delayMilliseconds(250);
      getDataFromExcel(activePage);
      nextStep();
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
      dispatch(
        onPushAndAutoRemoveNotification({
          isLoading: false,
          description: error,
        })
      );

      setIsLoading(false);
    }
  }, [valueSelect, file, activePage]);

  const handleNextStepMappingData = useCallback(async () => {
    // onGetPreviewFromExcelFile();
    setIsLoading(true);
    await delayMilliseconds(1500);
    setIsLoading(false);
    nextStep();
    nextStep();
  }, []);

  useEffect(() => {
    setOpened({});
    setChecked({});
    setColumnObjectsDefListImport({});
  }, [valueSelect]);

  const renderTab = useCallback(() => {
    switch (active) {
      case 0:
        return (
          <UpFileTab
            nextStep={handleNextStepUpFile}
            valueSelect={valueSelect}
            setValueSelects={setValueSelects}
            file={file}
            setFile={setFile}
            isLoading={isLoading}
          />
        );

      case 1:
        return (
          <FieldTab
            responseUpFile={responseUpFile}
            valueSelect={valueSelect}
            handleNextStepMappingData={handleNextStepMappingData}
            prevStep={prevStep}
            isLoading={isLoading}
            opened={opened}
            setOpened={setOpened}
            checked={checked}
            setChecked={setChecked}
            columnObjectsDefListImport={columnObjectsDefListImport}
            setColumnObjectsDefListImport={setColumnObjectsDefListImport}
            getDataFromExcel={getDataFromExcel}
            isLoadingLoadDataExcel={isLoadingLoadDataExcel}
            active={active}
            activePage={activePage}
            setPage={setPage}
          />
        );

      case 2:
      case 3:
        return <FinishTabMapping />;

      default:
        return null;
    }
  }, [
    nextStep,
    valueSelect,
    file,
    setFile,
    responseUpFile,
    prevStep,
    isLoading,
    opened,
    setOpened,
    checked,
    setChecked,
    columnObjectsDefListImport,
    setColumnObjectsDefListImport,
    getDataFromExcel,
    isLoadingLoadDataExcel,
    active,
    activePage,
    setPage,
  ]);

  return (
    <Modal
      styles={modalStyle}
      centered
      size={'900px'}
      opened={isShowModal}
      onClose={closeModal}
      title="Import Excel Data"
    >
      <div style={{ height: '100%' }}>
        <Stepper
          active={active}
          onStepClick={setActive}
          breakpoint="sm"
          size="sm"
          iconSize={32}
          styles={stepperModalImportExcelStyles}
        >
          <Stepper.Step
            label="Upload File"
            allowStepSelect={active > 0}
            loading={isLoading && active === 0}
          />
          <Stepper.Step
            label="Field Map"
            allowStepSelect={active > 1}
            loading={isLoading && active === 1}
          />
          <Stepper.Step label="Finish" allowStepSelect={false} />
        </Stepper>
        <Space h={20} />
        <div
          style={{
            height: heightScreen / 1.8,
          }}
        >
          {renderTab()}
        </div>
      </div>
    </Modal>
  );
}
// mantine-rqvegp mantine-Stepper-separator
