import { createSelector } from 'reselect';
import { palette1, palette2, palette3, palette4 } from '../../constants/colors';
import { paletteList } from '../../constants/newPalette';

export const themeModeSelector = (state) => state?.theme?.themeMode;

export const isDarkModeSelector = (state) => state?.theme?.themeMode === 'dark';

export const currentPaletteSelector = (state) => state?.theme?.currentPalette;

export const currentPaletteActiveSelector = createSelector(
  (state) => currentPaletteIndexSelector(state),
  (currentPaletteIndex) => {
    // currentPaletteIndex = 0;
    return {
      isDark: paletteList?.isDark[currentPaletteIndex],
      previewPalette: {
        backgroundColor:
          paletteList?.previewPalette?.backgroundColor[currentPaletteIndex],
      },

      dsBase: paletteList?.dsBase[currentPaletteIndex],
      dsBase60: paletteList?.dsBase60[currentPaletteIndex],
      dsBase80: paletteList?.dsBase80[currentPaletteIndex],
      dsBaseIntense: paletteList?.dsBaseIntense[currentPaletteIndex],

      dsBgBase: paletteList?.dsBgBase[currentPaletteIndex],
      dsBgBase60: paletteList?.dsBgBase60[currentPaletteIndex],
      dsBgBase80: paletteList?.dsBgBase80[currentPaletteIndex],
      dsBgLower: paletteList?.dsBgLower[currentPaletteIndex],
      dsBgOverlay: paletteList?.dsBgOverlay[currentPaletteIndex],
      dsBgSecondary: paletteList?.dsBgSecondary[currentPaletteIndex],

      dsBorderBase: paletteList?.dsBorderBase[currentPaletteIndex],
      dsBorderIntense: paletteList?.dsBorderIntense[currentPaletteIndex],

      dsIconActive: paletteList?.dsIconActive[currentPaletteIndex],
      dsIconBase: paletteList?.dsIconBase[currentPaletteIndex],
      dsIconSecondary: paletteList?.dsIconSecondary[currentPaletteIndex],

      dsTextActive: paletteList?.dsTextActive[currentPaletteIndex],
      dsTextBase: paletteList?.dsTextBase[currentPaletteIndex],
      dsTextInvert: paletteList?.dsTextInvert[currentPaletteIndex],
      dsTextMuted: paletteList?.dsTextMuted[currentPaletteIndex],
      dsTextSecondary: paletteList?.dsTextSecondary[currentPaletteIndex],
      dsTextTitle: paletteList?.dsTextTitle[currentPaletteIndex],
      dsTextWhite: paletteList?.dsTextWhite[currentPaletteIndex],

      // constant colora (used for the perspectives panel and other elements)
      dscIconActive: paletteList?.dscIconActive,
      dscIconBase: paletteList?.dscIconBase,
      dscTextActive: paletteList?.dscTextActive,
      dscTextBase: paletteList?.dscTextBase,
      dscTextSecondary: paletteList?.dscTextSecondary,

      dscBgBase: paletteList?.dscBgBase,
      dscBgSecondary: paletteList?.dscBgSecondary,

      objFilter: {
        background: paletteList?.objFilter?.background[currentPaletteIndex],
        backgroundContent:
          paletteList?.objFilter?.backgroundContent[currentPaletteIndex],
        btnBackground: paletteList?.objFilter?.btn[currentPaletteIndex],
      },
      paletteBtnColor: {
        from: paletteList?.paletteBtnColor?.from[currentPaletteIndex],
        to: paletteList?.paletteBtnColor?.to[currentPaletteIndex],
        deg: paletteList?.paletteBtnColor?.to[currentPaletteIndex],
      },
      logo: paletteList?.logo[currentPaletteIndex],
      body: {
        backgroundColor:
          paletteList?.body?.backgroundColor[currentPaletteIndex],
        borderContent: paletteList?.body?.borderContent[currentPaletteIndex],
        contentNodeBackground:
          paletteList?.body?.contentNodeBackground[currentPaletteIndex],
        smallTag: paletteList?.body?.smallTag[currentPaletteIndex],
        profileModalBackground:
          paletteList?.body?.profileModalBackground[currentPaletteIndex],
      },
      floatingButton: {
        backgroundColor:
          paletteList?.floatingButton?.backgroundColor[currentPaletteIndex],
        hoverColor:
          paletteList?.floatingButton?.hoverColor[currentPaletteIndex],
      },
      pnlPersp: {
        backgroundColor:
          paletteList?.pnlPersp?.backgroundColor[currentPaletteIndex],
        background: paletteList?.pnlPersp?.background[currentPaletteIndex],
      },
      border: {
        regular: paletteList?.border?.regular[currentPaletteIndex],
        boxShadow: paletteList?.border?.boxShadow[currentPaletteIndex],
        hiddenBoxShadow:
          paletteList?.border?.hiddenBoxShadow[currentPaletteIndex],
        borderHightLight:
          paletteList?.border?.borderHightLight[currentPaletteIndex],
      },
      text: {
        primary: paletteList?.text?.primary[currentPaletteIndex],
        secondary: paletteList?.text?.secondary[currentPaletteIndex],
        disable: paletteList?.text?.disable[currentPaletteIndex],
        highlight: paletteList?.text?.highlight[currentPaletteIndex],
        description: paletteList?.text?.description[currentPaletteIndex], // using for description
        active: paletteList?.text?.active[currentPaletteIndex],
        highlight_blur: paletteList?.text?.highlight_blur[currentPaletteIndex],
        perspectives: paletteList?.text?.perspectives[currentPaletteIndex],
        perspectivesHover:
          paletteList?.text?.perspectivesHover[currentPaletteIndex],
        primary1: paletteList?.text?.primary1[currentPaletteIndex], //  black || white
        title: paletteList?.text?.hightLight1[currentPaletteIndex], // using for title text
        subTitle: paletteList?.text?.subTitle[currentPaletteIndex], // using for title text
      },
      button: {
        primaryButton: {
          backgroundColor:
            paletteList?.button?.primaryButton?.backgroundColor[
              currentPaletteIndex
            ],
          backgroundColorActive:
            paletteList?.button?.primaryButton?.backgroundColorActive[
              currentPaletteIndex
            ],
          backgroundColorHover:
            paletteList?.button?.primaryButton?.backgroundColorHover[
              currentPaletteIndex
            ],
        },
        secondaryButton: {
          backgroundColor:
            paletteList?.button?.secondaryButton?.backgroundColor[
              currentPaletteIndex
            ],
          backgroundColorActive:
            paletteList?.button?.secondaryButton?.backgroundColorActive[
              currentPaletteIndex
            ],
          backgroundColorHover:
            paletteList?.button?.secondaryButton?.backgroundColorHover[
              currentPaletteIndex
            ],
        },
      },
      select: {
        backgroundColor:
          paletteList.select.backgroundColor[currentPaletteIndex],
        backgroundColorSelectObj:
          paletteList.select.backgroundColorSelectObj[currentPaletteIndex],
      },
      objForm: {
        backgroundColor:
          paletteList?.objForm?.backgroundColor[currentPaletteIndex],
      },
      activityLog: {
        backgroundColor:
          paletteList?.activityLog?.backgroundColor[currentPaletteIndex],
        backgroundColorBlur:
          paletteList?.activityLog?.backgroundColorBlur[currentPaletteIndex],
        colBgBreadCrumb:
          paletteList?.activityLog?.colBgBreadCrumb[currentPaletteIndex],
      },

      colIconButton: paletteList?.colIconButton[currentPaletteIndex],
      colBgMain: paletteList?.colBgMain[currentPaletteIndex],
      colBgDisc: paletteList?.colBgDisc[currentPaletteIndex],
      colBgObjAll: paletteList?.colBgObjAll[currentPaletteIndex],
      colBgFilterItem: [paletteList?.colBgFilterItem[currentPaletteIndex]],
      colBgObjMain: paletteList?.colBgObjMain[currentPaletteIndex],
      colBgObjTransp: paletteList?.colBgObjTransp[currentPaletteIndex],
      colBgHover: paletteList?.colBgHover[currentPaletteIndex],
      colBgForm: paletteList?.colBgForm[currentPaletteIndex],
      colBgFld: paletteList?.colBgFld[currentPaletteIndex],
      colLbl: paletteList?.colLbl[currentPaletteIndex],
      colLblDisc: paletteList?.colLblDisc[currentPaletteIndex],
      colFldVal: paletteList?.colFldVal[currentPaletteIndex],
      colFldValOnLight: paletteList?.colFldValOnLight[currentPaletteIndex],
      colFldValDisc: paletteList?.colFldValDisc[currentPaletteIndex],
      colTxt: paletteList?.colTxt[currentPaletteIndex],
      colTxtHeader: paletteList?.colTxtHeader[currentPaletteIndex],
      colTxtHeaderOnLight:
        paletteList?.colTxtHeaderOnLight[currentPaletteIndex],
      colTxtOnLight: paletteList?.colTxtOnLight[currentPaletteIndex],
      colTxtTitle: paletteList?.colTxtTitle[currentPaletteIndex],
      colTxtFlash: paletteList?.colTxtFlash[currentPaletteIndex],
      colBgFlash: paletteList?.colBgFlash[currentPaletteIndex],
      colArrow: paletteList?.colArrow[currentPaletteIndex],
      colLineSeparator: paletteList?.colLineSeparator[currentPaletteIndex],
      colBorderDark: paletteList?.colBorderDark[currentPaletteIndex],
      colBorder: paletteList?.colBorder[currentPaletteIndex],
      colBorderLight: paletteList?.colBorderLight[currentPaletteIndex],
    };
  }
);

const getPaletteByTheme = (isDark, palette) => {
  if (isDark) return palette?.dark;
  return palette?.light;
};
export const currentPaletteColorSelector = (state) => {
  const currentPalette = currentPaletteSelector(state);
  const isDark = isDarkModeSelector(state);

  switch (currentPalette) {
    case 'palette1':
      return getPaletteByTheme(isDark, palette1);
    case 'palette2':
      return getPaletteByTheme(isDark, palette2);
    case 'palette3':
      return getPaletteByTheme(isDark, palette3);
    case 'palette4':
      return getPaletteByTheme(isDark, palette4);
    default:
      return getPaletteByTheme(isDark, palette1);
  }
};

export const currentPaletteIndexSelector = (state) =>
  state?.theme?.currentPaletteIndex;
