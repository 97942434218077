import throttle from 'lodash/throttle';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { StyledResizePanel, stylesResizable } from './ResizablePanelCSS.js';

const MIN_WIDTH = 600;
class ResizablePanel extends React.Component {
  constructor(props) {
    super(props);
    this.handleMouseDownLimitBar = this.handleMouseDownLimitBar.bind(this);
    this.handleMouseUpLimitBar = this.handleMouseUpLimitBar.bind(this);
    this.handleMouseMoveLimitBar = throttle(
      this.handleMouseMoveLimitBar.bind(this),
      100
    );

    this.state = {
      moving: false,
      width: this.props.width,
      panelXCoord: 0,
    };
  }

  render() {
    const {
      objectDetailXPosition,
      phoneModel,
      resizeType,
      objDetailsOpen,
      isOpen,
      id,
      children,
    } = this.props;
    const { width } = this.state;

    return (
      <StyledResizePanel
        ref={this.props.innerRef}
        right={
          resizeType === 'activity_log_resize' &&
          objDetailsOpen &&
          `${objectDetailXPosition + 5}px`
        }
        isOpen={isOpen}
        width={width}
        phoneModel={phoneModel}
        id={id}
      >
        <NavigTreeResizePaneBar
          id={id}
          onMouseDown={this.handleMouseDownLimitBar}
        />
        <div style={stylesResizable.resizeContentContainer}>{children}</div>
      </StyledResizePanel>
    );
  }

  //------------------------------------------------------------------------------------------------------------
  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUpLimitBar);
    window.removeEventListener('mousemove', this.handleMouseMoveLimitBar);
  }

  //------------------------------------------------------------------------------------------------------------
  componentDidMount() {
    window.addEventListener('mouseup', this.handleMouseUpLimitBar);
    window.addEventListener('mousemove', this.handleMouseMoveLimitBar);
    const posRight = document
      .getElementById('pnl_objForm')
      .getClientRects()[0].right;

    if (this.props?.resizeType !== 'activity_log_resize') {
      this.props.onSetPositionXObjectDetail(+this.state.width);
    }

    this.setState({
      panelXCoord: posRight,
    });
  }

  //------------------------------------------------------------------------------------------------------------
  handleMouseDownLimitBar(evt) {
    this.setState({
      moving: true,
      previousClientX: evt.clientX - 10 + window.scrollX,
      previousWidth: this.props.width,
    });
  }

  //------------------------------------------------------------------------------------------------------------
  handleMouseUpLimitBar(evt) {
    this.setState({ moving: false });
  }

  //------------------------------------------------------------------------------------------------------------
  handleMouseMoveLimitBar(evt) {
    evt.stopImmediatePropagation();
    evt.preventDefault();
    const isPanelOpen = this.props.objId && this.props.objDetailsOpen;

    if (this.state.moving) {
      const newWidth = this.state.panelXCoord - evt.clientX;

      if (newWidth > MIN_WIDTH) {
        if (this.props?.resizeType !== 'activity_log_resize') {
          this.props.onSetPositionXObjectDetail(newWidth);
        }

        if (this.props?.resizeType === 'activity_log_resize') {
          if (
            isPanelOpen &&
            newWidth - this.props.objectDetailXPosition < MIN_WIDTH
          ) {
            return;
          }
          this.setState({
            width: isPanelOpen
              ? newWidth - this.props.objectDetailXPosition
              : newWidth,
          });
        } else {
          this.setState({ width: newWidth });
        }
      }
    }
  }
}

// STYLES ---------------------------------------------------------------------
const NavigTreeResizePaneBar = (props) => {
  return (
    <NavigTreeResizePaneBarContainer
      color={'var(--ds-dg-base)'}
      id={props.id}
      onMouseDown={props.onMouseDown}
    />
  );
};

const NavigTreeResizePaneBarContainer = styled.div({
  backgroundColor: (props) => props?.color || 'white',
  borderRight: (props) => `15px solid ${props?.color || 'white'}`,
  cursor: 'col-resize',
  display: 'inline-block',
  draggable: 'false',
  marginTop: '0px',
  marginLeft: '-0px',
  minHeight: '100%',
  minWidth: '15px',
  opacity: 1,
  width: '20px',
});

export default memo(ResizablePanel);
