import ObjectActionCreators from '../../actions';
import ObjectApi from '../../api/ObjectApi';
import { joinPattern } from '../../components/Utils/TextUtils';
import store from '../../store/configureStore';
import { refreshTreeByPattern } from '../objectReducers/action';
import { OBJECTS_TYPE } from '../objectReducers/type';
import { LAYOUT_TYPE } from './type';

export const setZoom = (value) => {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE.SET_ZOOM, value });
  };
};

export const setZoomMap = (value) => {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE.SET_ZOOM_MAP, value });
  };
};

export const togglePalette = () => {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE.TOGGLE_PALETTE });
  };
};

export const openObjectConfigure = (posX, posY, objId) => {
  return (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.CONFIGURE_OPEN_SCREEN,
      posX: 50,
      posY: 50,
      objId: null,
    });
  };
};

export const closeObjectConfigure = () => {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE.CONFIGURE_CLOSE_SCREEN });
  };
};

export const togglePerspectivesPanel = () => {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE.TOGGLE_PERSPECTIVES_PANEL });
    dispatch({ type: OBJECTS_TYPE.RESET_ACTIVE_PANEL });
  };
};

export const toggleVR = () => {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE.TOGGLE_VR });
  };
};

export const remoteControllerShowToggle = (x, y) => {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE.REMOTE_CTRL_SHOW_TOGGLE, x, y });
  };
};

export const openObjectDetails = () => {
  return (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.OBJECT_DETAILS_OPEN,
      objDetailsOpen: true,
    });
  };
};
export const closeObjectDetails = () => {
  return (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.OBJECT_DETAILS_OPEN,
      objDetailsOpen: false,
    });
  };
};
export const toggleShowSettings = () => {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE.TOGGLE_SHOW_SETTINGS });
  };
};

export const layersControllerShowToggle = () => {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE.LAYERS_CTRL_SHOW_TOGGLE });
  };
};

export const setScrollPos = (scrollX, scrollY) => {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE.SCROLL_POS, scrollX, scrollY });
  };
};

export const setMousePos = (x, y) => {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE.SET_MOUSE_POS, x, y });
  };
};

export const updateLayersDisplay = (dataId, dataAction) => {
  return (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.UPDATE_LAYERS_DISPLAY,
      dataId,
      dataAction,
    });
  };
};

export const onSelectOptionMenu = (option) => {
  return (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.SET_MENU_ACTIVE,
      option,
    });
  };
};

export const onResetOptionMenu = (option) => {
  return (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.RESET_MENU_OPTION,
      option,
    });
  };
};

export const onShowProfileView = (option) => {
  return (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.SHOW_PROFILE_VIEW,
    });
  };
};

export const onHiddenProfileView = (option) => {
  return (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.HIDDEN_PROFILE_VIEW,
    });
  };
};

export const onChangeActivityLog = (value) => {
  return (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.CHANGE_STATUS_ACTIVITY_LOG,
      value,
    });
  };
};

export const onActiveActivityLog = (objId) => {
  return async (dispatch) => {
    dispatch(onChangeActivityLog(true));
    dispatch(onSetCurrentObId(objId));
    dispatch(ObjectActionCreators.getCrumbTrail(objId));
  };
};

export const onSetCurrentObId = (objId) => {
  return async (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.SET_CURRENT_OBJ_ID,
      objId,
    });
  };
};

export const addNodeToReadLayer = (layerId, nodeId) => {
  return (dispatch) => {
    dispatch({ type: 'ADD_NODE_TO_LAYER', layerId, nodeId });
    ObjectApi.addNodeToReadLayer(layerId, nodeId).then(
      (objects) => {
        dispatch(ObjectActionCreators.selectObject(nodeId));
        dispatch(refreshTreeByPattern());
        dispatch({
          type: OBJECTS_TYPE.ADDED_NODE_TO_LAYER,
          success: true,
          objects,
        });
      },
      (error) =>
        dispatch({ type: OBJECTS_TYPE.ADDED_NODE_TO_LAYER, success: false })
    );
  };
};

export const addNodeToWriteLayer = (layerId, nodeId) => {
  return (dispatch) => {
    dispatch({ type: 'ADD_NODE_TO_LAYER', layerId, nodeId });
    ObjectApi.addNodeToWriteLayer(layerId, nodeId).then(
      (objects) => {
        dispatch(ObjectActionCreators.selectObject(nodeId));
        dispatch(refreshTreeByPattern());
        dispatch({
          type: OBJECTS_TYPE.ADDED_NODE_TO_LAYER,
          success: true,
          objects,
        });
      },
      (error) =>
        dispatch({ type: OBJECTS_TYPE.ADDED_NODE_TO_LAYER, success: false })
    );
  };
};

export const removeNodeFromReadLayer = (layerId, nodeId) => {
  return (dispatch) => {
    dispatch({ type: 'REMOVE_NODE_FROM_LAYER', layerId, nodeId });
    ObjectApi.removeNodeFromReadLayer(layerId, nodeId).then(
      (objects) => {
        dispatch(ObjectActionCreators.selectObject(nodeId));
        dispatch(refreshTreeByPattern());

        dispatch({ type: 'REMOVED_NODE_FROM_LAYER', success: true, objects });
      },
      (error) => dispatch({ type: 'REMOVED_NODE_FROM_LAYER', success: false })
    );
  };
};

export const removeNodeFromWriteLayer = (layerId, nodeId) => {
  return (dispatch) => {
    dispatch({ type: 'REMOVE_NODE_FROM_LAYER', layerId, nodeId });
    ObjectApi.removeNodeFromWriteLayer(layerId, nodeId).then(
      (objects) => {
        dispatch(ObjectActionCreators.selectObject(nodeId));
        dispatch(refreshTreeByPattern());

        dispatch({ type: 'REMOVED_NODE_FROM_LAYER', success: true, objects });
      },
      (error) => dispatch({ type: 'REMOVED_NODE_FROM_LAYER', success: false })
    );
  };
};

export const applyAudienceRightsToChilds = (nodeId) => {
  return (dispatch) => {
    dispatch({ type: 'APPLY_AUDIENCE_RIGHTS_TO_CHILDS', nodeId });
    ObjectApi.applyAudienceRightsToChilds(nodeId).then(
      (objects) => {
        dispatch(ObjectActionCreators.selectObject(nodeId));
        dispatch(refreshTreeByPattern());

        dispatch({
          type: 'APPLIED_AUDIENCE_RIGHTS_TO_CHILDS',
          success: true,
          objects,
        });
      },
      (error) =>
        dispatch({ type: 'APPLIED_AUDIENCE_RIGHTS_TO_CHILDS', success: false })
    );
  };
};

export const updateLayoutDocument = (objId, fldName) => {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_LAYOUT_DOCUMENT', objId });
    ObjectApi.updateLayoutDocument(objId, fldName).then(
      (objects) =>
        dispatch({ type: 'UPDATED_LAYOUT_DOCUMENT', success: true, objects }),
      (error) => dispatch({ type: 'UPDATED_LAYOUT_DOCUMENT', success: false })
    );
  };
};
export const getLayers = () => {
  return (dispatch) => {
    dispatch({ type: 'GET_LAYERS' });
    const state = store.getState();
    ObjectApi.getTreeByPattern(
      joinPattern(['layer', 'none', 'none']),
      state.QBUILDER.qb_restrictions
    ).then(
      (objects) => {
        dispatch({
          type: OBJECTS_TYPE.RECEIVE_LAYERS,
          success: true,
          objects,
        });
      },
      (error) => dispatch({ type: OBJECTS_TYPE.RECEIVE_LAYERS, success: false })
    );
  };
};

export const draggedOnNode = (nodeId, dragged_node_key) => {
  const state = store.getState();
  return (dispatch) => {
    dispatch({ type: 'DRAG_ON_NODE', nodeId });
    if (
      (state.objects.dragged_node_key !== null &&
        nodeId !== null &&
        state.objects.dragged_node_key !== nodeId) ||
      (dragged_node_key && nodeId)
    ) {
      ObjectApi.draggedOnNode(
        dragged_node_key ?? state.objects.dragged_node_key,
        nodeId
      ).then((objects) => {
        dispatch(refreshTreeByPattern());
      });
    }
  };
};

export const onSetPositionXObjectDetail = (objectDetailXPosition) => {
  return async (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.SET_POSITION_X_OBJ_DETAIL,
      objectDetailXPosition,
    });
  };
};
export const onSetSelectNode = (node) => {
  return async (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.SET_SELECT_NODE,
      node,
    });
  };
};

export const onShowSelectedNode = (status) => {
  return async (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.SET_PRESS_SHIFT,
      status,
    });
  };
};

export const onResetSelectNode = () => {
  return async (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.RESET_SELECT_NODE,
    });
  };
};
export const setShowMenuMobile = (value) => {
  return async (dispatch, getState) => {
    const isShowMenuMobile = getState()?.layout?.isShowMenuMobile;
    if (isShowMenuMobile && value) return;
    dispatch({
      type: LAYOUT_TYPE.SET_SHOW_MENU_MOBILE,
      value,
    });
  };
};

export const onTogglePreviewSvgSketch = (status) => {
  return async (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.TOGGLE_PREVIEW_SVG_SKETCH,
      status,
    });
  };
};

export const setPreviewSvgSketchData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.SET_PREVIEW_SVG_SKETCH_DATA,
      data,
    });
  };
};

export const setStatusModalImportFile = (status) => {
  return (dispatch) => {
    dispatch({
      type: LAYOUT_TYPE.SET_STATUS_MODAL_IMPORT_MAPPING_FILE,
      status,
    });
  };
};
