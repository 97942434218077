export const PATTERN_SEPARATOR = '___'; // XXX to do centralize in config
export const abbreviateName = (label, minChar = 4, joinWith = '.') => {
  if (typeof label === 'string' && label.length > minChar) {
    let words = label.split(' ');
    let charsNeeded = minChar; // The minimum number of characters for the abbreviation
    let charsPerWord = Math.ceil(charsNeeded / words.length);
    let abbreviation = words
      .map((word) => word.slice(0, charsPerWord))
      .join(joinWith);

    // If the abbreviation is still too short (e.g. due to short words), take more characters
    while (abbreviation.replace(/\./g, '').length < charsNeeded) {
      for (
        let i = 0;
        i < words.length &&
        abbreviation.replace(/\./g, '').length < charsNeeded;
        i++
      ) {
        abbreviation += `.${words[i].slice(charsPerWord, charsPerWord + 1)}`;
        charsPerWord++;
      }
    }

    // If the abbreviation is too long, cut it down to the required size
    if (abbreviation.replace(/\./g, '').length > charsNeeded) {
      abbreviation = abbreviation.slice(0, charsNeeded * 2 - 1); // Multiply charsNeeded by 2 to account for the dots
    }

    // Ensure there's no trailing dot
    if (!abbreviation.endsWith(joinWith)) {
      abbreviation += joinWith;
    }

    return abbreviation;
  }
  return label;
};

export const splitPattern = (pattern) => {
  if (pattern.includes(PATTERN_SEPARATOR)) {
    return pattern.split(PATTERN_SEPARATOR);
  }
  return pattern.split('_');
};

export const joinPattern = (parts) => {
  return parts.join(PATTERN_SEPARATOR);
};

export const removeQuote = (str) => {
  if (str.startsWith('"') && str.endsWith('"')) {
    return str.slice(1, -1);
  }
  return str;
};
