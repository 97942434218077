import produce from 'immer';
import { OBJECTS_TYPE } from '../objectReducers/type';

const gridReducer = (
  state = {
    gridTemplates: ['', '', ''],
  },
  action,
  root
) => {
  switch (action.type) {
    case OBJECTS_TYPE.SET_ACTIVE_BAND:
      return produce(state, (draft) => {
        draft.activePanel = action.level;
        draft.activeBand = action.band;
      });

    default:
      return state;
  }
};
export default gridReducer;
