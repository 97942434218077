import { uuidv4 } from '../../utils/uuidv4';
import { NOTIFICATION_TYPE } from './type';

export const onPushNotification = ({
  title,
  description,
  disallowClose,
  notificationId,
  isLoading,
  ...props
}) => {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATION_TYPE.PUSH_NOTIFICATION,
      title,
      description,
      disallowClose,
      notificationId,
      isLoading,
      ...props,
    });
  };
};

export const onRemoveNotification = ({ notificationId }) => {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATION_TYPE.REMOVE_NOTIFICATION,
      notificationId,
    });
  };
};

export const onPushAndAutoRemoveNotification = ({
  title = 'notification',
  description,
  disallowClose = false,
  time = 5000,
  isLoading = false,

  ...props
}) => {
  const notificationId = uuidv4();
  return (dispatch) => {
    dispatch(
      onPushNotification({
        title,
        description,
        disallowClose,
        notificationId,
        isLoading,
        ...props,
      })
    );
    setTimeout(() => {
      dispatch(onRemoveNotification({ notificationId }));
    }, time);
  };
};
