import LAYOUT_REDUCER from '../components/Utils/layoutReducer.js';
import { authReducer } from './authReducer/authReducer';
import { iconsLibraryReducer } from './iconsLibraryReducer/iconsLibraryReducer.js';

import CLASSIFIER_REDUCER from './classifierReducer.js';
import CONFIG from './configReducer.js';
import { layoutReducer } from './layoutReducer/layoutReducer';
import { notificationReducer } from './notificationReducer/notificationReducer.js';
import OBJECT_CREATOR from './ObjectCreatorReducer.js';
import OBJECT_SELECTOR from './ObjectSelectorReducer.js';
import objects from './objectReducers/objectsReducers.js';
import QBUILDER from './queryBuilderReducers';
import responsiveReducer from './responsiveReducer/responsiveReducer.js';
import themeReducer from './themeReducers/themeReducer.js';
import profileReducer from './profileReducer/profileReducer';
import gridReducer from './gridReducer/gridReducer.js';

let rootReducer;

function lastAction(state = null, action, root) {
  return action;
}

export default rootReducer = (state = {}, action) => {
  return {
    auth: authReducer(state.auth, action, state),
    iconsLibrary: iconsLibraryReducer(state.iconsLibrary, action, state),

    layout: layoutReducer(state.layout, action, state),
    OBJECT_CREATOR: OBJECT_CREATOR(state.OBJECT_CREATOR, action, state),
    OBJECT_SELECTOR: OBJECT_SELECTOR(state.OBJECT_SELECTOR, action, state),
    CONFIG: CONFIG(state.CONFIG, action, state),
    objects: objects(state.objects, action, state),
    responsive: responsiveReducer(state.responsive, action, state),
    profile: profileReducer(state.profile, action, state),

    theme: themeReducer(state.theme, action, state),
    notificationState: notificationReducer(
      state.notificationState,
      action,
      state
    ),
    QBUILDER: QBUILDER(state.QBUILDER, action, state),
    LAYOUT_REDUCER: LAYOUT_REDUCER(state.LAYOUT_REDUCER, action, state),
    CLASSIFIER_REDUCER: CLASSIFIER_REDUCER(
      state.CLASSIFIER_REDUCER,
      action,
      state
    ),
    grid: gridReducer(state.grid, action, state),
    lastAction: lastAction(state, action, state),
  };
};
